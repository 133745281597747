import { ClipLoader } from "react-spinners";
import { useState } from "react";

import Loader from "../Loader";
import EmptyTable from "../EmptyTable";
import useCancelInvite from "../../api/invitation/useCancelInvite";

const InvitesTable = ({ loading, data, fetchInvites }) => {
  const [selectedInvite, setSelectedInvite] = useState(null);
  const { request: cancelInvite, loading: deleting } = useCancelInvite();

  async function deleteInvite(params) {
    setSelectedInvite(params);

    await cancelInvite(params?.inviteId);

    setSelectedInvite(null);
    await fetchInvites();
  }

  return (
    <div className="table_wrapper">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-4 border-b">
          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">Email Address</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Date Sent</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm"> </h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data ? (
          <EmptyTable
            //   image={subjectBookImage}
            message={"No invite record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div className="grid grid-cols-4 border-b" key={i}>
            <div className="border-r p-3 col-span-2 flex items-center">
              <p className="text-independence text-sm">{item?.email}</p>
            </div>

            <div className="p-3 border-r flex items-center">
              <p className="text-independence text-sm">
                {new Date(item?.createdAt)?.toLocaleDateString()}
              </p>
            </div>

            <div className="p-3">
              <button
                className="text-sm flex items-center justify-center gap-1 py-2 px-3 rounded-md cursor-pointer w-fit text-coral_red hover:bg-coral_red hover:text-white"
                onClick={() => deleteInvite(item)}
              >
                {deleting && selectedInvite?.inviteId === item?.inviteId ? (
                  <ClipLoader loading={deleting} size={14} color="#fff" />
                ) : (
                  <small>Cancel invite</small>
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default InvitesTable;
