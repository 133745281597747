import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

const useFetchTeachers = (schoolId, page, setPagination) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const res = await client.get(`/school/${schoolId}/teachers?page=${page}`);

      setData(res.data?.data);
      if (res.data?.pagination && setPagination)
        setPagination(res.data?.pagination);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [schoolId, page, setPagination]);

  useEffect(() => {
    if (schoolId) request();
  }, [request, schoolId]);

  return { loading, data, request };
};

export default useFetchTeachers;
