import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PageLoader from "../../../shared/components/PageLoader";
import SideModal from "../../../shared/components/general/SideModal";
import BasicDetails from "../../../shared/components/classes/editStudent/BasicDetails";
import MedicalDetails from "../../../shared/components/classes/editStudent/MedicalDetails";
import useFetchStudentInfo from "../../../shared/api/classes/students/useFetchStudentInfo";
import ClassHistories from "../../../shared/components/classes/editStudent/ClassHistories";
import GuardianDetails from "../../../shared/components/classes/editStudent/GuardianDetails";
import BasicDetailsForm from "../../../shared/components/classes/editStudent/BasicDetailsForm";
import MedicalDetailsForm from "../../../shared/components/classes/editStudent/MedicalDetailsForm";
import GuardianDetailsForm from "../../../shared/components/classes/editStudent/GuardianDetailsForm";
import useFetchStudentClassHistory from "../../../shared/api/archives/students/useFetchStudentClassHistory";
import ClassHistoryForm from "../../../shared/components/classes/editStudent/ClassHistoryForm";

const StudentDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get("type");
  const selected = query.get("selected");
  const { schoolId } = useParams();

  const navigate = useNavigate();
  const { classId, studentId } = useParams();

  const { data: guardian } = useFetchStudentInfo(studentId, "guardian");
  const {
    data: studentDetails,
    studentLoading,
    request: refreshBasicDetails,
  } = useFetchStudentInfo(studentId, "basic");
  const { data: classHistories, request: fetchClassHistory } =
    useFetchStudentClassHistory();

  useEffect(() => {
    fetchClassHistory({
      studentId,
    });
  }, [fetchClassHistory, studentId]);

  function onEditProfile(params) {
    navigate(
      `/${schoolId}/classes/${classId}/student/${studentId}?type=${params}`
    );
  }

  function onEditClassHistory(params) {
    navigate(
      `/${schoolId}/classes/${classId}/student/${studentId}?type=history&selected=${params?.studentClassHistoryId}`
    );
  }

  function closeSideModal(params) {
    navigate(`/${schoolId}/classes/${classId}/student/${studentId}`);

    if (params?.refresh) {
      refreshBasicDetails(studentId, params?.type);
      fetchClassHistory({ studentId });
    }
  }

  if (studentLoading) {
    return <PageLoader loading={studentLoading} />;
  }

  const modalIsVisible = type;
  return (
    <div className={`full_page`}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">
            {studentDetails?.lastName} {studentDetails?.firstName} (
            {studentDetails?.category} {studentDetails?.suffix})
          </h1>
        </div>
      </div>

      {/* <EditStudentNavigator type={type} /> */}

      <div className="mb-10 slg:flex slg:items-start slg:justify-between">
        <div className="mb-8 slg:w-[59%] slg:mb-0">
          <BasicDetails
            onEdit={onEditProfile}
            details={studentDetails}
            category={studentDetails?.category}
            suffix={studentDetails?.suffix}
          />
        </div>

        <div className="slg:w-[40%]">
          <ClassHistories
            classHistories={classHistories}
            onEditClassHistory={onEditClassHistory}
          />
        </div>
      </div>

      <div className="grid gap-8 slg:grid-cols-2 slg:gap-4">
        <MedicalDetails details={studentDetails} onEdit={onEditProfile} />

        <GuardianDetails guardian={guardian} onEdit={onEditProfile} />
      </div>

      <SideModal
        float={true}
        isVisible={modalIsVisible}
        closeSideModal={closeSideModal}
      >
        {type === "basic" ? (
          <BasicDetailsForm
            details={studentDetails}
            category={studentDetails?.category}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {type === "medical" ? (
          <MedicalDetailsForm
            details={studentDetails}
            closeSideModal={closeSideModal}
          />
        ) : null}

        {type === "guardian" ? (
          <GuardianDetailsForm closeSideModal={closeSideModal} />
        ) : null}

        {type === "history" ? (
          <ClassHistoryForm
            selected={selected}
            closeSideModal={closeSideModal}
            category={studentDetails?.category}
          />
        ) : null}
      </SideModal>
    </div>
  );
};

export default StudentDetails;
