import { useNavigate, useParams } from "react-router-dom";
import EmptyTable from "../EmptyTable";
import Loader from "../Loader";

const TeachersTable = ({ loading, data, userId }) => {
  const { schoolId } = useParams();
  const navigate = useNavigate();

  function handleNavigate(params) {
    navigate(`/${schoolId}/teachers?selected=${params?.userId}`);
  }

  return (
    <div className="table_wrapper">
      <div className="min-w-[900px]">
        {/* table header */}
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Name</h5>
          </div>

          <div className="border-r p-3 col-span-2">
            <h5 className="font-semibold text-sm">Email Address</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Phone Number</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Class Roles</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Subject Roles</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Date Joined</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            //   image={subjectBookImage}
            message={"No teacher record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid grid-cols-8 border-b table_row ${
              userId === item?.userId ? "active_table_row" : ""
            }`}
            key={i}
            onClick={() => handleNavigate(item)}
          >
            <div className="col-span-2 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.lastName} {item?.firstName}
              </p>
            </div>

            <div className="border-r p-3 col-span-2">
              <p className="text-independence text-sm">{item?.email}</p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">{item?.phone}</p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.classRoles}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.subjectRoles}
              </p>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {new Date(item?.createdAt)?.toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TeachersTable;
