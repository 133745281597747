import { useCallback, useState } from "react";
import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useAddSubjectToSchoolCurriculum() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (schoolId, payload) => {
    try {
      setLoading(true);
      const { data } = await client.post(
        `/curriculum/school/${schoolId}/addSubject`,
        payload
      );

      setLoading(false);
      handleToast(data?.message);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
