import FormButton from "../../form/FormButton";
import FormInput from "../../form/FormInput";
import SelectInput from "../../form/SelectInput";

const NewStudentForm = ({
  onSubmit,
  student,
  guardian,
  setGuardian,
  setStudent,
  loading,
  category,
}) => {
  return (
    <div className="px-5">
      <h3 className="text-black-text font-semibold mb-5">
        Student information
      </h3>

      <div className="grid gap-4 md:grid-cols-2">
        {studentForm.map((field, i) => (
          <FormInput
            key={i}
            type={field.type}
            label={field.label}
            placeholder={field.placeholder}
            required={field.required}
            maxLength={field.maxLength}
            className="mb-0"
            onChange={(e) =>
              setStudent({
                ...student,
                [field.name]: e.currentTarget.value,
              })
            }
          />
        ))}

        <SelectInput
          label={"Gender"}
          placeholder="Gender"
          required
          handleSelect={(e) =>
            setStudent({ ...student, gender: e.currentTarget.value })
          }
          options={[
            { name: "male", value: "male" },
            { name: "female", value: "female" },
          ]}
          className="mb-0"
        />

        {category?.startsWith("S") ? (
          <SelectInput
            label={"Department"}
            placeholder="Department"
            required
            handleSelect={(e) =>
              setStudent({ ...student, department: e.currentTarget.value })
            }
            options={[
              { name: "Science Department", value: "science" },
              { name: "Art Department", value: "art" },
              { name: "Commercial Department", value: "commercial" },
            ]}
            className="mb-0"
          />
        ) : null}
      </div>

      {/* Guardian details */}
      <div className="mt-10">
        <h3 className="text-black-text font-semibold mb-5">
          Guardian information
        </h3>

        <div className="grid gap-4 md:grid-cols-2">
          {guardianForm.map((field, i) => (
            <FormInput
              key={i}
              label={field.label}
              maxLength={field.maxLength}
              required={field.required}
              placeholder={field.placeholder}
              className="mb-0"
              onChange={(e) =>
                setGuardian({
                  ...guardian,
                  [field.name]: e.currentTarget.value,
                })
              }
            />
          ))}
        </div>
      </div>

      <div className="max-w-xs mx-auto my-10">
        <FormButton
          title={"Register Student"}
          loading={loading}
          type={"submit"}
          onClick={onSubmit}
        />

        {/* <div style={{ minWidth: "100px" }} /> */}
      </div>
    </div>
  );
};
export default NewStudentForm;

const guardianForm = [
  {
    label: "Last Name",
    name: "name",
    required: true,
    placeholder: "Enter guardian last name",
  },
  {
    label: "Address",
    name: "address",
    placeholder: "Enter guardian address",
    required: true,
  },
  {
    label: "Phone Number",
    name: "phone",
    placeholder: "Enter guardian phone number",
    maxLength: 11,
    required: true,
    type: "number",
  },
  {
    label: "Occupation",
    name: "occupation",
    placeholder: "Enter guardian occupation",
  },
];

const studentForm = [
  {
    label: "First Name",
    name: "firstName",
    placeholder: "Enter student first name",
    required: true,
  },
  {
    label: "Last Name",
    name: "lastName",
    placeholder: "Enter student last name",
    required: true,
  },
  {
    label: "Middle Name",
    name: "middleName",
    placeholder: "Enter student middle name",
  },
  {
    label: "Date Of Birth",
    name: "dob",
    placeholder: "Enter student date of birth",
    required: true,
    type: "date",
  },
];
