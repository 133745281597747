import { useCallback, useState } from "react";
import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";
import { useNavigate, useParams } from "react-router-dom";

export default function useSaveToSchoolCurriculum() {
  const navigate = useNavigate();
  const { schoolId } = useParams();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (category, payload) => {
      try {
        setLoading(true);

        const { data } = await client.post(
          `/curriculum/school/${schoolId}/sort/${category}`,
          payload
        );

        if (data?.success) {
          navigate(`/${schoolId}/settings/curriculum/${category}`);
        }

        setLoading(false);
        handleToast(data?.message);
        return true;
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [navigate, schoolId]
  );

  return { request, loading };
}
