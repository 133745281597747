import React from "react";
import { CiEdit } from "react-icons/ci";
import IMAGES from "../../../constant/images";
import DataPlaceholder from "../../general/DataPlaceholder";

export default function BasicDetails({ details, onEdit, category, suffix }) {
  console.log(details, "details");
  return (
    <div className="bg-white px-3 py-5 rounded-sm">
      <div className="flex items-center justify-between mb-3">
        <h4>Basic Information</h4>

        <button
          className="flex items-center gap-2 text-primary text-sm"
          onClick={() => onEdit("basic")}
        >
          <span>Edit</span>
          <CiEdit />
        </button>
      </div>

      <div className="grid sm:grid-cols-3 gap-5">
        {/**
        |--------------------------------------------------
        | Profile image and name
        |--------------------------------------------------
        */}
        <div className="">
          <div className="w-full h-52 rounded-sm overflow-hidden">
            <img
              src={IMAGES.studentAvatar}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>

          <h3 className="font-bold text-yankee_blue text-lg capitalize mt-5">
            {details?.lastName || ""} {details?.firstName || ""}{" "}
            {details?.middleName || ""}
          </h3>
          <p className="text-grey text-sm">
            Student in {category || ""} ({suffix})
          </p>
        </div>

        {/**
        |--------------------------------------------------
        | Details
        |--------------------------------------------------
        */}
        <div className="col-span-2 grid grid-cols-2 gap-y-4">
          <DataPlaceholder value={details?.firstName} label={"First Name"} />
          <DataPlaceholder
            label={"Last Name"}
            value={details?.lastName || "N/A"}
          />
          <DataPlaceholder
            label={"Middle Name"}
            value={details?.middleName || "N/A"}
          />

          <DataPlaceholder value={details?.gender || "N/A"} label={"Gender"} />
          <DataPlaceholder
            label={"Email address"}
            value={details?.email || "N/A"}
          />

          <DataPlaceholder
            label={"Religion"}
            value={details?.religion || "N/A"}
          />
          <DataPlaceholder value={details?.phone || "N/A"} label={"Phone"} />
          <DataPlaceholder value={details?.dob || "N/A"} label={"D.O.B"} />
          <DataPlaceholder
            value={details?.ethnicity || "N/A"}
            label={"Ethnicity"}
          />
          <DataPlaceholder
            value={details?.department || "N/A"}
            label={"Department"}
          />
          <DataPlaceholder
            label={"Address"}
            value={details?.address || "N/A"}
            // className="col-span-2"
          />
          <DataPlaceholder
            value={details?.nationality || "N/A"}
            label={"Nationality"}
          />
          <DataPlaceholder
            value={details?.state || "N/A"}
            label={"State of origin"}
          />
          <DataPlaceholder value={details?.lga || "N/A"} label={"L.G.A"} />
        </div>
      </div>
    </div>
  );
}
