import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

const useFetchClassDetails = (classId) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const request = useCallback(async () => {
    setLoading(true);

    try {
      const res = await client.get(`/classes/${classId}`);

      setData(res.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [classId]);

  useEffect(() => {
    if (classId) request();
  }, [request, classId]);

  return { loading, data, request };
};
export default useFetchClassDetails;
