import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { handleToast } from "../../../shared/utils/functions";
import SideModal from "../../../shared/components/general/SideModal";
import SelectInput from "../../../shared/components/form/SelectInput";
import { selectCalendar } from "../../../redux/slices/school/selectors";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import useFetchClassReportCard from "../../../shared/api/cards/useFetchClassReportCard";
import useFetchStudentBasicInfo from "../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchAcademicSessions from "../../../shared/api/archives/useFetchAcademicSessions";
import ReportCardTable from "../../../shared/components/classes/reportCards/ReportCardTable";
import useFetchClassSubjects from "../../../shared/api/classes/subjects/useFetchClassSubjects";
import ReportCardOverview from "../../../shared/components/classes/reportCards/ReportCardOverview";
import UpdateStudentClass from "../../../shared/components/classes/studentReportCard/UpdateStudentClass";
import useUpdateStudentClassHistory from "../../../shared/api/archives/students/useUpdateStudentClassHistory";

const ReportCards = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");
  const term = query.get("term");
  const markStudentAs = query.get("markStudentAs");
  const studentId = query.get("studentId");

  const navigate = useNavigate();
  const { classId, schoolId } = useParams();
  const currentCalendar = useSelector(selectCalendar);

  const { data: academicSessions } = useFetchAcademicSessions();
  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const { loading: subjectLoading, data: subjects } =
    useFetchClassSubjects(classId);

  const {
    data: reportCards,
    loading: reportCardsLoading,
    request: fetchReportCards,
  } = useFetchClassReportCard(session, term);

  const { loading: studentDetailsLoading, data: studentDetails } =
    useFetchStudentBasicInfo(studentId);

  const { request: updateStudentClassRequest, loading: updating } =
    useUpdateStudentClassHistory();

  async function updateStudentClassHistory() {
    const found = reportCards?.find((el) => el?.studentId === studentId);
    // console.log(found, "found");

    if (
      (found?.category === "JSS3" || found?.category === "SSS3") &
      (found?.term === "second")
    ) {
      return handleToast(
        "Graduating student can only be marked on second term report card",
        "warning"
      );
    }

    if (found?.term !== "third") {
      return handleToast(
        "Student can only be marked on third term report card",
        "warning"
      );
    }

    const payload = {
      classId,
      studentId,
      status: markStudentAs,
      studentClassHistoryId: found?.studentClassHistory?.studentClassHistoryId,
    };

    if (markStudentAs === "left") payload.term = found?.term;

    const isSuccesful = await updateStudentClassRequest(payload);
    if (isSuccesful) {
      await fetchReportCards();
      closeSideModal();
    }
  }

  useEffect(() => {
    if ((session === "null" && term === "null") || (!session && !term)) {
      navigate(
        `/${schoolId}/classes/${classId}/reportcards?session=${currentCalendar?.session}&term=${currentCalendar?.term}`
      );
    }
  }, [
    session,
    term,
    navigate,
    currentCalendar?.session,
    currentCalendar?.term,
    classId,
    schoolId,
  ]);

  function handleSelect(params) {
    navigate(
      `/${schoolId}/classes/${classId}/students/${params?.studentId}/reportcard?session=${session}&term=${term}`
    );
  }

  function handleSelectTerm(_term) {
    navigate(
      `/${schoolId}/classes/${classId}/reportcards?session=${session}&term=${_term}`
    );
  }

  function handleSelectSession(_session) {
    navigate(
      `/${schoolId}/classes/${classId}/reportcards?session=${_session}&term=${term}`
    );
  }

  function closeSideModal() {
    navigate(
      `/${schoolId}/classes/${classId}/reportcards?session=${session}&term=${term}`
    );
  }

  function handleMark(status, studentId) {
    navigate(
      `/${schoolId}/classes/${classId}/reportcards?studentId=${studentId}&markStudentAs=${status}&session=${session}&term=${term}`
    );
  }

  return (
    <div>
      <div className="full_page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">Report Cards</h1>

            <p className="page_subtitle">
              {classDetails
                ? `(${classDetails?.category} ${classDetails?.suffix})`
                : null}
            </p>
          </div>
        </div>

        {/* <ClassNavigationBar activeIndex={5} /> */}
        <div className="flex items-center gap-2 mb-2 justify-end">
          <SelectInput
            value={session || ""}
            placeholder={"Session"}
            optionKeyLabel="session"
            options={academicSessions}
            optionValueLabel="session"
            containerStyle={"border-0"}
            className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
            handleSelect={(e) => handleSelectSession(e.currentTarget.value)}
          />

          <SelectInput
            value={term || ""}
            placeholder={"Term"}
            containerStyle={"border-0"}
            options={[
              { name: "First Term", value: "first" },
              { name: "Second Term", value: "second" },
              { name: "Third Term", value: "third" },
            ]}
            className="min-w-[110px] mb-0 bg-white ssm:min-w-[150px]"
            handleSelect={(e) => handleSelectTerm(e.currentTarget.value)}
          />
        </div>

        <div className="main_content">
          <ReportCardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            subjectLength={subjects?.length}
            reportCardsLength={reportCards?.length}
          />

          <ReportCardTable
            data={reportCards}
            handleMark={handleMark}
            handleSelect={handleSelect}
            loading={reportCardsLoading}
            subjectLoading={subjectLoading}
          />
        </div>
      </div>

      <SideModal isVisible={markStudentAs} closeSideModal={closeSideModal}>
        <UpdateStudentClass
          updating={updating}
          markStudentAs={markStudentAs}
          handleCancel={closeSideModal}
          currentCalendar={currentCalendar}
          handleUpdate={updateStudentClassHistory}
          studentDetailsLoading={studentDetailsLoading}
          studentName={`${studentDetails?.lastName || ""} ${
            studentDetails?.firstName || ""
          }'s`}
        />
      </SideModal>
    </div>
  );
};

export default ReportCards;
