import { useEffect, useState } from "react";
import { Country, State } from "country-state-city";
import { useParams } from "react-router-dom";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import SelectInput from "../../form/SelectInput";
import useFetchStudentInfo from "../../../api/classes/students/useFetchStudentInfo";
import useUpdateStudentInfo from "../../../api/classes/students/useUpdateStudentInfo";

const GuardianDetailsForm = ({ closeSideModal }) => {
  const { studentId } = useParams();
  const { loading, data: guardianDetails } = useFetchStudentInfo(
    studentId,
    "guardian"
  );
  const { request: updateGuardianRequest } = useUpdateStudentInfo();

  const [guardian, setGuardian] = useState({});

  useEffect(() => {
    setGuardian(guardianDetails);
  }, [guardianDetails]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  async function updateGuardianInfo() {
    if (selectedCountry && !selectedState) {
      const country = Country.getCountryByCode(selectedCountry);
      guardian.nationality = country.name;
    }

    if (selectedCountry && selectedState) {
      const country = Country.getCountryByCode(selectedCountry);
      const state = State.getStateByCodeAndCountry(
        selectedState,
        selectedCountry
      );

      guardian.nationality = country.name;
      guardian.stateOfOrigin = state.name;
    }

    const isSuccessful = await updateGuardianRequest(
      studentId,
      "guardian",
      guardian
    );
    if (isSuccessful) closeSideModal({ refresh: true, type: "guardian" });
  }

  const countries = Country.getAllCountries()?.map((country) => ({
    value: country.isoCode,
    ...country,
  }));

  const states = (countryCode) =>
    State.getStatesOfCountry(countryCode).map((state) => ({
      value: state.isoCode,
      ...state,
    }));

  if (loading) return <Loader loading={loading} />;

  return (
    <div className="">
      <h3 className="text-black-text font-semibold mb-5">
        Guardian information
      </h3>

      <div className="">
        <FormInput
          label={"Last name"}
          value={guardian?.name || ""}
          onChange={(e) =>
            setGuardian({
              ...guardian,
              name: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Email address"}
          value={guardian?.email || ""}
          onChange={(e) =>
            setGuardian({
              ...guardian,
              email: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Home address"}
          value={guardian?.address || ""}
          onChange={(e) =>
            setGuardian({
              ...guardian,
              address: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Contact"}
          value={guardian?.phone || ""}
          maxLength={11}
          onChange={(e) =>
            setGuardian({
              ...guardian,
              phone: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Occupation"}
          value={guardian?.occupation || ""}
          onChange={(e) =>
            setGuardian({
              ...guardian,
              occupation: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <SelectInput
          label={"Religion"}
          placeholder="Religion"
          value={guardian?.religion}
          handleSelect={(e) =>
            setGuardian({ ...guardian, religion: e.currentTarget.value })
          }
          options={[
            { name: "Islam", value: "Islam" },
            { name: "Christianity", value: "Christianity" },
            { name: "Other", value: "Other" },
          ]}
          className="mb-2"
        />

        <SelectInput
          label={"Marital status"}
          placeholder="Marital status"
          value={guardian?.maritalStatus}
          handleSelect={(e) =>
            setGuardian({ ...guardian, maritalStatus: e.currentTarget.value })
          }
          options={[
            { name: "Married", value: "Married" },
            { name: "Single", value: "Single" },
            { name: "Divorced", value: "Divorced" },
          ]}
          className="mb-2"
        />

        <SelectInput
          label={`Nationality`}
          placeholder={guardian?.nationality}
          handleSelect={(e) => setSelectedCountry(e.currentTarget.value)}
          options={countries}
          className="mb-2"
        />

        <SelectInput
          label={`State of origin`}
          placeholder={guardian?.stateOfOrigin}
          handleSelect={(e) => setSelectedState(e.currentTarget.value)}
          options={states(selectedCountry)}
          className="mb-2"
        />

        <FormInput
          label={"L.G.A"}
          value={guardian?.lga || ""}
          onChange={(e) =>
            setGuardian({
              ...guardian,
              lga: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Ethnicity"}
          value={guardian?.ethnicity || ""}
          onChange={(e) =>
            setGuardian({
              ...guardian,
              ethnicity: e.currentTarget.value,
            })
          }
          className="mb-2"
        />
      </div>

      <div className="border max-w-xs mx-auto my-10">
        <FormButton
          title={"Update"}
          onClick={updateGuardianInfo}
          loading={loading}
        />

        {/* <div style={{ minWidth: "100px" }} /> */}
      </div>
    </div>
  );
};
export default GuardianDetailsForm;
