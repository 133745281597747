import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import FormButton from "../../../../shared/components/form/FormButton";
import ChooseTeacher from "../../../../shared/components/ChooseTeacher";
import useFetchTeachers from "../../../../shared/api/teachers/useFetchTeachers";
import useModifyClassTeacher from "../../../../shared/api/classes/teachers/useModifyClassTeacher";

const ModifyClassTeacher = ({ classDetails, action, refresh }) => {
  const navigate = useNavigate();
  const { schoolId, classId } = useParams();
  const calendar = useSelector(selectCalendar);

  const { loading: teachersLoading, data: teachers } =
    useFetchTeachers(schoolId);

  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const { request: modifyClassTeacherRequest, loading: updatingTeacher } =
    useModifyClassTeacher(false);

  async function modifyClassTeacher() {
    await modifyClassTeacherRequest({
      teacherId: selectedTeacher?.userId,
      session: calendar?.session,
      term: calendar?.term,
    });

    await refresh();
  }

  function handleCancel() {
    navigate(`/${schoolId}/classes/${classId}`);
  }

  if (action !== "class-teacher") return null;

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h6 className="text-yankee_blue font-semibold capitalize">
          Modify Class Teacher
        </h6>
      </div>

      <div className="mb-10">
        <p className="text-sm text-independence">Current teacher</p>
        <p className="text-base text-yankee_blue capitalize">
          {classDetails?.teacherTitle} {classDetails?.teacherLastName}{" "}
          {classDetails?.teacherFirstName}
        </p>
      </div>

      <ChooseTeacher
        teachers={teachers}
        teachersLoading={teachersLoading}
        handleSelectTeacher={setSelectedTeacher}
        selectedTeacher={selectedTeacher}
      />

      <FormButton
        title={"Update teacher"}
        onClick={modifyClassTeacher}
        loading={updatingTeacher}
      />

      <p
        className="text-center text-sm text-coral_red mt-1 py-3 cursor-pointer"
        onClick={handleCancel}
      >
        Cancel
      </p>
    </div>
  );
};
export default ModifyClassTeacher;
