import { useCallback, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchEventById() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);

      const res = await client.get(`/calendar-event/${id}`);
      setData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { data, loading, request };
}
