import { FiUsers } from "react-icons/fi";
import { getTotalStudents } from "../../../utils/functions";

import Loader from "../../Loader";
import TableOverviewCard from "../../TableOverviewCard";

const ReportCardOverview = ({
  classDetails,
  classLoading,
  subjectLength,
  reportCardsLength,
}) => {
  return (
    <>
      {classLoading ? (
        <Loader loading={classLoading} className={"min-h-[20px]"} size={20} />
      ) : null}

      <div className="table_wrapper">
        <div className="table_header !min-w-[800px]">
          <div className="pl-3"></div>

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Students"}
            value={getTotalStudents(classDetails) || "-"}
            valueBackgroundColor={"bg-azureish_white"}
            valueColor={"text-primary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Report Cards"}
            value={reportCardsLength}
            valueBackgroundColor={"bg-water"}
            valueColor={"text-secondary"}
          />

          <TableOverviewCard
            icon={<FiUsers className="text-morning_blue" />}
            label={"Total Subjects"}
            value={subjectLength || "-"}
            valueBackgroundColor={"bg-banana_mania"}
            valueColor={"text-saffron"}
          />
        </div>
      </div>
    </>
  );
};
export default ReportCardOverview;
