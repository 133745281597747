import { useCallback, useState } from "react";

import client from "../../client";
import { handleToast } from "../../../utils/functions";
import handleApiError from "../../../hooks/handleApiError";

export default function useUpdateStudentClassHistory() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (payload) => {
    try {
      setLoading(true);

      const { data } = await client.post(
        `/archives/update-class-history/student/${payload?.studentId}`,
        payload
      );

      setLoading(false);
      handleToast(data?.message);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
