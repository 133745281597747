import React, { useState } from "react";
import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import { useParams } from "react-router-dom";

import { handleToast } from "../../utils/functions";
import useInviteTeacherBySchool from "../../api/invitation/useInviteTeacherBySchool";

export default function NewTeacher() {
  const { schoolId } = useParams();

  const [email, setEmail] = useState("");
  const { request, loading } = useInviteTeacherBySchool();

  async function sendInvite() {
    if (!email) return handleToast("Email address is required");
    await request({ email }, schoolId);
  }

  return (
    <div>
      <h1 className="side_page_title">Add New Teacher</h1>

      {/* <h2 className="text-lg text-black font-semibold">Invite Teacher</h2> */}
      <p className="text-dark_electric_blue text-sm">
        Invite a teacher to your school to collaborate in your workspace
      </p>

      <div className="max-w-md my-10">
        <FormInput
          placeholder={"Enter teacher email"}
          required
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
        />

        <FormButton
          title={"Send invite"}
          onClick={sendInvite}
          loading={loading}
        />
      </div>
    </div>
  );
}
