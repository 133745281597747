import { useSelector } from "react-redux";
import { FcCancel } from "react-icons/fc";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { AiFillEdit, AiTwotoneSave } from "react-icons/ai";

import { selectSchoolDetails } from "../../../redux/slices/school/selectors";

import Avatar from "../../../shared/components/Avatar";
import FormInput from "../../../shared/components/form/FormInput";
import useFetchSchool from "../../../shared/api/school/useFetchSchool";
import useUpdateSchool from "../../../shared/api/school/useUpdateSchool";

const SchoolProfile = () => {
  const { schoolId } = useParams();

  const filePickerRef = useRef(null);
  const school = useSelector(selectSchoolDetails);

  const { request: fetchSchool } = useFetchSchool();

  const { request: updateSchool, loading } = useUpdateSchool();
  const [disable, setDisable] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [schoolDetails, setSchoolDetails] = useState({
    motto: "",
    address: "",
    name: "",
    country: "",
    logo: "",
    lga: "",
    state: "",
    registrationNumber: "",
    phone: "",
    phone2: "",
    email: "",
  });

  useEffect(() => {
    setSchoolDetails({
      motto: school?.motto || "",
      address: school?.address || "",
      name: school?.name || "",
      country: school?.country || "",
      logo: school?.logo || "",
      lga: school?.lga || "",
      state: school?.state || "",
      registrationNumber: school?.registrationNumber || "",
      phone: school?.phone || "",
      phone2: school?.phone2 || "",
      email: school?.email || "",
    });
  }, [school]);

  function resetFilePickerRef() {
    filePickerRef.current.value = "";
    setSelectedFile(null);
    setSelectedImage(null);
  }

  function selectImage(e) {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }

    reader.onload = (readerEvent) => {
      setSelectedImage(readerEvent.target.result);
    };

    setSelectedFile(e.target.files[0]);
  }

  async function submit() {
    const formData = new FormData();

    formData.append("image", selectedFile);
    formData.append("motto", schoolDetails?.motto);
    formData.append("address", schoolDetails?.address);
    formData.append("name", schoolDetails?.name);
    formData.append("country", schoolDetails?.country);
    formData.append("lga", schoolDetails?.lga);
    formData.append("state", schoolDetails?.state);
    formData.append("registrationNumber", schoolDetails?.registrationNumber);
    formData.append("phone", schoolDetails?.phone);
    formData.append("phone2", schoolDetails?.phone2);
    formData.append("email", schoolDetails?.email);

    await updateSchool(formData, schoolId);

    setDisable(true);
    fetchSchool(schoolId);
  }

  return (
    <div>
      <div className="page">
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">School Details</h1>
        </div>

        <div className="main_content px-5">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            encType="multipart/form-data"
          >
            <div className="flex items-center justify-center flex-col gap-2 mb-10">
              <Avatar imageUrl={selectedImage || school?.logo} size={80} />

              <input
                type={"file"}
                hidden
                name="image"
                ref={filePickerRef}
                onChange={selectImage}
                accept=".jpg, .jpeg, .png"
              />

              <div className="flex gap-2">
                {disable ? null : (
                  <button
                    className="flex items-center text-xs md:text-sm gap-1 rounded-sm px-2 py-1 cursor-pointer bg-primary text-white font-semibold"
                    onClick={() => filePickerRef.current.click()}
                  >
                    <small>Change image</small>
                  </button>
                )}

                {selectedFile && !disable ? (
                  <button
                    className="flex items-center text-xs md:text-sm gap-1 rounded-sm px-2 py-1 cursor-pointer bg-white text-coral_red border border-coral_red font-semibold"
                    onClick={() => resetFilePickerRef()}
                  >
                    <small>Cancel image</small>
                  </button>
                ) : null}
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4">
              <FormInput
                label={"School Name"}
                value={schoolDetails?.name}
                required
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    name: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"School Address"}
                required
                disabled={disable}
                value={schoolDetails?.address}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    address: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"School Phone 1"}
                value={schoolDetails?.phone}
                maxLength={11}
                minLength={11}
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    phone: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"School Phone 2"}
                value={schoolDetails?.phone2}
                maxLength={11}
                minLength={11}
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    phone2: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"School Email"}
                value={schoolDetails?.email}
                required
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    email: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"School Motto"}
                value={schoolDetails?.motto}
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    motto: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"State"}
                value={schoolDetails?.state}
                required
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    state: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"Country"}
                value={schoolDetails?.country}
                required
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    country: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"School Registration Number"}
                value={schoolDetails?.registrationNumber}
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    registrationNumber: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />

              <FormInput
                label={"L.G.A"}
                value={schoolDetails?.lga}
                required
                disabled={disable}
                onChange={(e) =>
                  setSchoolDetails({
                    ...schoolDetails,
                    lga: e.currentTarget.value,
                  })
                }
                className={"mb-0"}
              />
            </div>

            <div className="grid md:grid-cols-2 mt-10 gap-4">
              <div className="grid grid-cols-2 gap-4">
                {disable ? (
                  <button
                    className="flex items-center justify-center text-xs md:text-sm gap-2 rounded-md px-2 py-1 cursor-pointer bg-primary text-white font-semibold h-10"
                    onClick={() => setDisable(false)}
                  >
                    <AiFillEdit color="#fff" size={20} />
                    <small>Edit</small>
                  </button>
                ) : null}

                {!disable ? (
                  <button
                    className="flex items-center justify-center text-xs md:text-sm gap-2 rounded-md px-2 py-1 cursor-pointer bg-white text-coral_red border border-cotext-coral_red font-semibold"
                    onClick={() => {
                      setDisable(true);
                      setSelectedFile(null);
                    }}
                  >
                    <FcCancel />
                    <small>Cancel</small>
                  </button>
                ) : null}

                {!disable ? (
                  <button
                    className="flex items-center justify-center text-xs md:text-sm gap-2 border rounded-md px-2 py-1 cursor-pointer bg-primary text-white font-semibold h-10"
                    onClick={() => submit(false)}
                  >
                    {loading ? (
                      <ClipLoader loading={loading} size={16} color="#fff" />
                    ) : (
                      <>
                        <AiTwotoneSave color="#fff" size={20} />
                        <small>Save Changes</small>
                      </>
                    )}
                  </button>
                ) : null}
              </div>

              <div />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default SchoolProfile;
