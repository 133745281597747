import React from "react";
import Loader from "../../../../shared/components/Loader";

export default function StudentClassHistory({
  classHistory,
  loadingClassHistories,
}) {
  // const isGraduatingStudent =
  //   reportCard?.category === "JSS3" || reportCard?.category === "SSS3";
  // const navigate = useNavigate();
  // const { classId, studentId, schoolId } = useParams();

  // function handleUpdateStudent(status) {
  //   navigate(
  //     `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?markStudentAs=${status}`
  //   );
  // }

  if (loadingClassHistories) return <Loader loading={loadingClassHistories} />;
  if (!classHistory) return null;

  return (
    <div className="bg-white px-3 pb-3 flex flex-col justify-center md:flex-row gap-5 md:items-start">
      {classHistory?.endedSessionAs ? (
        <div className="flex items-center flex-wrap">
          <div className="flex items-center gap-2 border-r border-greyX11 pr-5">
            <p className="text-xs">Started session as:</p>
            <div className="py-1 w-fit px-3 flex items-center justify-center bg-water rounded-sm">
              <p className="font-bold capitalize text-sm text-secondary">
                {classHistory?.startedSessionAs === "entry"
                  ? "New Entry"
                  : classHistory?.startedSessionAs || "N/A"}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2 pl-5">
            <p className="text-xs">Ended session as:</p>
            <div className="py-1 w-fit px-3 flex items-center justify-center bg-yankee_blue/10 rounded-sm">
              <p className="font-bold capitalize text-sm text-yankee_blue">
                {classHistory?.endedSessionAs || "N/A"}
              </p>
            </div>
          </div>
        </div>
      ) : null}

      {/* {!classHistory?.endedSessionAs ? (
        <div className="flex items-center gap-2 flex-wrap">
          <div
            className="py-1 w-fit px-3 flex items-center justify-center bg-pale_pink rounded-sm cursor-pointer"
            onClick={() => handleUpdateStudent("left")}
          >
            <p className="text-sm text-coral_red">Student Left</p>
          </div>

          {reportCard && isGraduatingStudent ? (
            <div
              className="py-1 w-fit px-3 flex items-center justify-center bg-azureish_white rounded-sm cursor-pointer"
              onClick={() => handleUpdateStudent("graduated")}
            >
              <p className="text-sm text-primary">Graduate Student</p>
            </div>
          ) : null}

          {reportCard && !isGraduatingStudent ? (
            <>
              <div
                className="py-1 w-fit px-3 flex items-center justify-center bg-azureish_white rounded-sm cursor-pointer"
                onClick={() => handleUpdateStudent("promoted")}
              >
                <p className="text-sm text-primary">Promote Student</p>
              </div>

              <div
                className="py-1 w-fit px-3 flex items-center justify-center bg-banana_mania rounded-sm cursor-pointer"
                onClick={() => handleUpdateStudent("retained")}
              >
                <p className="text-sm text-saffron">Retain Student</p>
              </div>
            </>
          ) : null}
        </div>
      ) : null} */}
    </div>
  );
}
