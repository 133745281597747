import { useCallback, useState } from "react";

import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useAddPrincipalRemark() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (reportCardId, payload) => {
    try {
      const { data } = await client.patch(
        `/result/reportcard/updatePrincipalRemark/${reportCardId}`,
        payload
      );
      setLoading(false);
      handleToast(data?.message);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
