import client from "../client";
import { useCallback, useState } from "react";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useRemoveTeacherFromSchool() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (payload, schoolId) => {
    setLoading(true);

    try {
      const { data } = await client.post(
        `/school/${schoolId}/teachers/remove`,
        payload
      );

      handleToast(data?.message);
      setLoading(false);

      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
