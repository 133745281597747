import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useFetchArchivedStudents(session, type) {
  const { schoolId, category } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (_session) => {
      try {
        setData([]);
        setLoading(true);

        const res = await client.get(`/archives/archived-students`, {
          params: {
            school: schoolId,
            category:
              category === "junior"
                ? "JSS3"
                : category === "senior"
                ? "SSS3"
                : null,
            session: _session,
            endedSessionAs: type,
          },
        });

        setData(res.data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [category, schoolId, type]
  );

  const reset = () => {
    setData([]);
  };

  useEffect(() => {
    if (session) {
      request(session);
    }
  }, [session, request]);

  return { data, loading, reset };
}
