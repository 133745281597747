import { useEffect, useState } from "react";

import Loader from "../../Loader";
import FormButton from "../../form/FormButton";
import SelectInput from "../../form/SelectInput";
import useFetchClassHistoryById from "../../../api/archives/students/useFetchClassHistoryById";
import useUpdateClassHistoryById from "../../../api/archives/students/useUpdateClassHistoryById";

const ClassHistoryForm = ({ closeSideModal, selected, category }) => {
  const { loading, data: history } = useFetchClassHistoryById(selected);
  const { request: updateHistory } = useUpdateClassHistoryById();

  const [payload, setPayload] = useState({});

  useEffect(() => {
    setPayload({
      startedSessionAs: history?.startedSessionAs,
      endedSessionAs: history?.endedSessionAs,
    });
  }, [history]);

  async function update() {
    const isSuccessful = await updateHistory(selected, payload);
    if (isSuccessful) closeSideModal({ refresh: true, type: "history" });
  }

  function getEndSessionOptions() {
    const options = [
      { name: "Promoted", value: "promoted" },
      { name: "Retained", value: "retained" },
      { name: "Left", value: "left" },
    ];

    if (category === "JSS3" || category === "SSS3") {
      options.push({ name: "Graduated", value: "graduated" });
    }

    return options;
  }

  if (loading) return <Loader loading={loading} />;

  return (
    <div className="">
      <h3 className="text-black-text font-semibold mb-5">Class History</h3>

      <div className="">
        <SelectInput
          label={"Started Session As"}
          placeholder="Started session"
          value={payload?.startedSessionAs}
          handleSelect={(e) =>
            setPayload({ ...payload, startedSessionAs: e.currentTarget.value })
          }
          options={[
            { name: "Promoted", value: "promoted" },
            { name: "Graduated", value: "graduated" },
            { name: "Retained", value: "retained" },
            { name: "Entry", value: "entry" },
          ]}
          className="mb-2"
        />

        <SelectInput
          label={"Ended Session As"}
          placeholder="Ended session"
          value={payload?.endedSessionAs}
          handleSelect={(e) =>
            setPayload({ ...payload, endedSessionAs: e.currentTarget.value })
          }
          options={getEndSessionOptions()}
          className="mb-2"
        />

        {payload?.endedSessionAs === "left" && (
          <SelectInput
            label={"Term of leave"}
            placeholder="Select term"
            value={payload?.term}
            handleSelect={(e) =>
              setPayload({ ...payload, term: e.currentTarget.value })
            }
            options={[
              { name: "First Term", value: "first" },
              { name: "Second Term", value: "second" },
              { name: "Third Term", value: "third" },
            ]}
            className="mb-2"
          />
        )}
      </div>

      <div className="max-w-xs mx-auto my-10">
        <FormButton title={"Update"} onClick={update} loading={loading} />

        <p
          className="text-center text-sm text-coral_red mt-1 py-3 cursor-pointer"
          // onClick={handleCancel}
        >
          Delete Record
        </p>

        {/* <div style={{ minWidth: "100px" }} /> */}
      </div>
    </div>
  );
};
export default ClassHistoryForm;
