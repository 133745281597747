import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import { selectCalendar } from "../../../redux/slices/school/selectors";
import useRemoveTeacherFromSchool from "../../api/teachers/useRemoveTeacherFromSchool";

export default function RemoveTeacher({ details, closeSideModal }) {
  const { schoolId } = useParams();
  const currentCalendar = useSelector(selectCalendar);

  const { request, loading } = useRemoveTeacherFromSchool();

  async function handleContinue() {
    const isSuccess = await request(
      { teacherId: details?.userId, calendarId: currentCalendar?.calendarId },
      schoolId
    );

    if (isSuccess) {
      closeSideModal({ refresh: true });
    }
  }

  return (
    <div>
      <h1 className="side_page_title">Remove Teacher</h1>

      {/* <h2 className="text-lg text-black font-semibold">Invite Teacher</h2> */}
      <p className="text-dark_electric_blue text-sm mb-2">
        Remove this teacher from your school workspace
      </p>

      <p className="text-sm text-independence mb-1">
        - Teacher will be removed from your list of teachers
      </p>

      <p className="text-sm text-independence mb-1">
        - All active class roles and subject roles will be terminated
      </p>

      <div className="max-w-md my-10">
        <FormInput
          disabled
          placeholder={`${details?.title} ${details?.lastName} ${details?.firstName}`}
        />

        <FormButton
          loading={loading}
          onClick={handleContinue}
          title={"Remove Teacher"}
          className={"!bg-coral_red mt-5"}
        />
      </div>
    </div>
  );
}
