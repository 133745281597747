import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { handleToast } from "../../../utils/functions";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useAddStudentToClass() {
  const navigate = useNavigate();
  const { classId, schoolId } = useParams();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      setLoading(true);

      try {
        const res = await client.post(
          `/classes/v2/${classId}/students/new`,
          payload
        );

        setLoading(false);
        handleToast(res.data?.message);

        if (res.data?.success) navigate(`/${schoolId}/classes/${classId}`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [classId, schoolId, navigate]
  );

  return { request, loading };
}
