import { useEffect, useState } from "react";

import Loader from "../../../../shared/components/Loader";
import FormInput from "../../../../shared/components/form/FormInput";
import FormButton from "../../../../shared/components/form/FormButton";
import useUpdateScorecard from "../../../../shared/api/cards/useUpdateScorecard";
import useFetchScorecardById from "../../../../shared/api/cards/useFetchScorecardById";

const EditScorecard = ({
  fetchSubjectScorecard,
  scorecardId,
  handleCancelEditScorecard,
}) => {
  const [score, setScore] = useState({
    continuousAssessmentOneScore: 0,
    continuousAssessmentTwoScore: 0,
    examScore: 0,
  });

  const { data: scorecard, loading: loadingScorecard } =
    useFetchScorecardById(scorecardId);

  useEffect(() => {
    setScore({
      continuousAssessmentOneScore: scorecard?.continuousAssessmentOneScore,
      continuousAssessmentTwoScore: scorecard?.continuousAssessmentTwoScore,
      examScore: scorecard?.examScore,
    });
  }, [scorecard]);

  const { request: submitScoreRequest, loading: submitting } =
    useUpdateScorecard();

  async function submitScore() {
    const isSuccessful = await submitScoreRequest(scorecardId, score);

    if (isSuccessful) {
      setScore({
        continuousAssessmentOneScore: 0,
        continuousAssessmentTwoScore: 0,
        examScore: 0,
      });

      await fetchSubjectScorecard();
      handleCancelEditScorecard();
    }
  }

  if (!scorecardId) return null;

  if (loadingScorecard) return <Loader loading={loadingScorecard} />;

  return (
    <div>
      <div className="mb-5">
        <h3 className="side_page_title">Update Scorecard</h3>

        <h2 className="font-semibold text-yankee_sm mb-1 text-base capitalize">
          {scorecard?.name || ""}
        </h2>

        <p className="text-xs text-independence capitalize">
          Session: {scorecard?.session} ({scorecard?.term} term)
        </p>
      </div>

      <FormInput
        label="CA 1"
        value={score?.continuousAssessmentOneScore}
        onChange={(e) =>
          setScore({ ...score, continuousAssessmentOneScore: e.target.value })
        }
      />

      <FormInput
        label="CA 2"
        value={score?.continuousAssessmentTwoScore}
        onChange={(e) =>
          setScore({ ...score, continuousAssessmentTwoScore: e.target.value })
        }
      />

      <FormInput
        label="Exam"
        value={score?.examScore}
        onChange={(e) => setScore({ ...score, examScore: e.target.value })}
      />

      <FormButton title={"Update"} loading={submitting} onClick={submitScore} />

      <p
        className="text-center text-coral_red mt-1 py-3 cursor-pointer"
        onClick={handleCancelEditScorecard}
      >
        Cancel
      </p>
    </div>
  );
};

export default EditScorecard;
