import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import handleApiError from "../../hooks/handleApiError";
import client from "../client";

export default function useFetchAnnouncementById() {
  const { announcementId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await client.get(`/announcement/${id}`);
      setData(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (announcementId) request(announcementId);
  }, [request, announcementId]);

  return { data, loading };
}
