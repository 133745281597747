import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { setClassOverview } from "../../../redux/slices/overview/slice";

import handleApiError from "../../hooks/handleApiError";
import client from "../client";

export default function useFetchClassOverview() {
  const { schoolId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const request = useCallback(async () => {
    setLoading(true);
    dispatch(setClassOverview(null));

    try {
      const response = await client.get(`/overview/school/${schoolId}/classes`);

      dispatch(setClassOverview(response.data?.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, [dispatch, schoolId]);

  useEffect(() => {
    request();
  }, [request]);

  return { loading };
}
