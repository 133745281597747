import { useCallback, useState } from "react";

import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useCreateStudentReportCard() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (payload) => {
    setLoading(true);

    try {
      const { data } = await client.post(`/result/reportcard/new`, payload);
      setLoading(false);

      handleToast(data?.message);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
