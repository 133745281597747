import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { handleToast } from "../../../utils/functions";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useModifyClassTeacher() {
  const { classId, schoolId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);
        const { data } = await client.patch(
          `/classes/${classId}/teacher/update`,
          payload
        );

        setLoading(false);
        handleToast(data?.message);
        navigate(`/${schoolId}/classes/${classId}`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [classId, schoolId, navigate]
  );

  return { request, loading };
}
