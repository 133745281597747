import { BsArrowLeft } from "react-icons/bs";
import { NavLink, useParams } from "react-router-dom";

import Loader from "../../../shared/components/Loader";
import announcementImage from "../../../assets/images/Character.png";
import AnnouncementDetails from "../../../shared/components/announcements/AnnouncementDetails";
import useFetchAnnouncementById from "../../../shared/api/announcement/useFetchAnnouncementById";

const Announcement = () => {
  const { teacherId } = useParams();

  const { data: announcement, loading } = useFetchAnnouncementById(null);

  return (
    <div>
      <div className="page">
        <div className="flex items-center mb-10">
          <h1 className="page_title flex-1">Announcements</h1>
        </div>

        <div className="main_content_header">
          <NavLink to={`/${teacherId}/announcements`}>
            <div className="ml-5 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer hover:bg-slate-50">
              <BsArrowLeft />
            </div>
          </NavLink>
        </div>

        <div className="main_content">
          {loading ? <Loader loading={loading} /> : null}

          {!loading && !announcement ? (
            <div>
              <div className="w-[40%] mx-auto">
                <img
                  src={announcementImage}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>

              <p className="text-center px-[20%]">
                No announcement to read. Select a a announcement from the right
                bar to view announcement content
              </p>
            </div>
          ) : null}

          {!loading && announcement ? (
            <AnnouncementDetails announcement={announcement} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Announcement;
