import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Logo from "../../../shared/components/Logo";
import authImage from "../../../assets/images/signup.png";
import FormInput from "../../../shared/components/form/FormInput";
import FormButton from "../../../shared/components/form/FormButton";
import useStartResetPassword from "../../../shared/api/recovery/useStartResetPassword";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const { request: startPasswordRequest, loading } = useStartResetPassword();
  const [email, setEmail] = useState("");

  async function sendResetMail(e) {
    e.preventDefault();
    await startPasswordRequest({ email });
  }

  return (
    <div className="auth_page">
      <div className="auth_page_content_grid">
        <div className="auth_logo_wrapper">
          <Logo />
        </div>

        <div className="auth_page_content">
          <h2 className="auth_page_title">
            Enter email address to reset your password
          </h2>

          <form onSubmit={sendResetMail}>
            <FormInput
              label={"Email Address"}
              placeholder={"Enter email address"}
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />

            <FormButton
              title={"Reset Password"}
              type="submit"
              loading={loading}
              className="mt-[10%]"
            />

            <p
              className="text-yankee_blue text-xs mt-2 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Already have an account?{" "}
              <span className="text-primary">Sign In</span>
            </p>
          </form>
        </div>
      </div>

      <div className="auth_page_image_grid">
        <div className="auth_image_container">
          <img
            src={authImage}
            alt="GMF"
            className="block w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
