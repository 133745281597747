import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { handleToast } from "../../utils/functions";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useVerifyPasswordResetToken() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setLoading] = useState(false);

  const request = useCallback(async () => {
    setLoading(true);
    try {
      setLoading(true);

      const { data } = await client.post(`/auth/password/reset/verify`, {
        token,
      });

      if (data?.success) {
        navigate(`/password/change/${token}`);
      } else {
        handleToast(data?.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
      navigate("/login", { replace: true });
    }
  }, [navigate, token]);

  useEffect(() => {
    request();
  }, [request]);

  return { loading };
}
