import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";
import { setUserDetails } from "../../../redux/slices/auth/slice";
import { selectUserDetails } from "../../../redux/slices/auth/selectors";
import { selectSchoolDetails } from "../../../redux/slices/school/selectors";

export default function useFetchUserDetails() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const details = useSelector(selectUserDetails);
  const school = useSelector(selectSchoolDetails);

  const request = useCallback(
    async (_userId) => {
      setLoading(true);
      dispatch(setUserDetails(null));

      try {
        const { data } = await client.get(`/user/${_userId}`);
        if (data?.success) dispatch(setUserDetails(data?.data));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!details && school?.ownerId) request(school?.ownerId);
  }, [request, details, school?.ownerId]);

  return { request, loading };
}
