import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { selectSeniorSubjects } from "../../../redux/slices/school/selectors";
import { selectSeniorCurriculums } from "../../../redux/slices/curriculum/selectors";

import SideModal from "../../../shared/components/general/SideModal";
import SortSubjects from "../../../shared/components/settings/curriculums/SortSubjects";
import DeleteSubject from "../../../shared/components/settings/curriculums/DeleteSubject";
import useFetchSchoolCurriculum from "../../../shared/api/school/useFetchSchoolCurriculum";
import useFetchGeneralCurriculum from "../../../shared/api/school/useFetchGeneralCurriculum";
import CurriculumTable from "../../../shared/components/settings/curriculums/CurriculumTable";
import AddSubjectToCurriculum from "../../../shared/components/settings/curriculums/AddSubjectToCurriculum";
import CurriculumTableOverview from "../../../shared/components/settings/curriculums/CurriculumTableOverview";

const SeniorCurriculums = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");
  const selected = query.get("selected");

  const { schoolId } = useParams();
  const navigate = useNavigate();

  const seniorSubjects = useSelector(selectSeniorSubjects);
  const seniorCurriculum = useSelector(selectSeniorCurriculums);

  const { loading: seniorSubjectsLoading, request: fetchSeniorSubjects } =
    useFetchSchoolCurriculum("senior");
  const { loading: loadingCurriculum } = useFetchGeneralCurriculum("senior");

  function handleAddSubject() {
    navigate(`/${schoolId}/settings/curriculum/senior?action=add`);
  }

  function handleDeleteSubject(sub) {
    navigate(
      `/${schoolId}/settings/curriculum/senior?selected=${sub?.schoolCurriculumId}`
    );
  }

  function closeSideModal() {
    navigate(`/${schoolId}/settings/curriculum/senior`);
  }

  function handleSortSubject() {
    navigate(`/${schoolId}/settings/curriculum/senior?action=sort`);
  }

  return (
    <div className={action ? "page" : "full_page"}>
      <div className="mb-10 flex items-center justify-between">
        <h1 className="page_title">Senior Curriculums</h1>
      </div>

      <div className="main_content">
        <CurriculumTableOverview handleAddSubject={handleAddSubject} />
        <CurriculumTable
          data={seniorSubjects}
          handleDeleteSubject={handleDeleteSubject}
          emptyDataButtonTitle={"Sort curriculum"}
          handleEmptyData={handleSortSubject}
          selected={selected}
        />
      </div>

      <SideModal isVisible={action || selected} closeSideModal={closeSideModal}>
        <AddSubjectToCurriculum
          action={action}
          schoolSubjects={seniorSubjects}
          curriculum={seniorCurriculum}
          curriculumLoading={loadingCurriculum}
          schoolSubjectsLoading={seniorSubjectsLoading}
          closeSideModal={closeSideModal}
          refresh={fetchSeniorSubjects}
          category={"senior"}
        />

        <DeleteSubject
          schoolSubjectId={selected}
          closeSideModal={closeSideModal}
          refresh={fetchSeniorSubjects}
          action={action}
          category={"senior"}
        />

        <SortSubjects
          category={"senior"}
          action={action}
          refresh={fetchSeniorSubjects}
          closeSideModal={closeSideModal}
        />
      </SideModal>
    </div>
  );
};

export default SeniorCurriculums;
