import { useState } from "react";
import Loader from "../../Loader";
import EmptyTable from "../../EmptyTable";
import { getDayStringFromDate, handleToast } from "../../../utils/functions";

const AttendanceTable = ({
  studentId,
  loading,
  attendanceSheet,
  currentDate,
  markedStudentList,
  setMarkedStudentList,
  getDateFromResumptionDate,
  handleRowSelect,
}) => {
  const dayName = getDayStringFromDate(currentDate);
  const [showButton, setShowButton] = useState(false);
  const [index, setIndex] = useState(null);

  /**
  |--------------------------------------------------
  | handle showing absent and present button
  |--------------------------------------------------
  */
  function handleCellClick(day, index) {
    /**
    |--------------------------------------------------
    | check if cell clicked is the active day
    |--------------------------------------------------
    */
    if (dayName && day !== dayName) {
      return handleToast(
        `${dayName} is the active date. Click "${day}" to mark attendance.`,
        "warning"
      );
    }

    if (showButton) {
      setShowButton(false);
      return setIndex(null);
    }

    setShowButton(true);
    setIndex(index);
  }

  /**
  |--------------------------------------------------
  | handle marking student as present or absent
  |--------------------------------------------------
  */
  function handleMark(studentAttendanceId, status) {
    if (!studentAttendanceId) return;

    /**
    |--------------------------------------------------
    | initiate marked student list if it is empty
    |--------------------------------------------------
    */
    if (!markedStudentList?.length) {
      setMarkedStudentList([{ studentAttendanceId, status }]);
    }

    /**
    |--------------------------------------------------
    | check if student is already marked
    |--------------------------------------------------
    */
    const index = markedStudentList?.findIndex(
      (list) => list?.studentAttendanceId === studentAttendanceId
    );

    /**
    |--------------------------------------------------
    | update student status if it is already marked 
    |--------------------------------------------------
    */
    if (index >= 0) {
      const found = markedStudentList[index];

      if (found?.status !== status) {
        found.status = status;
        const newArr = [...markedStudentList];
        newArr[index] = found;

        setMarkedStudentList(newArr);
      }
    } else {
      /**
      |--------------------------------------------------
      | add student to marked student list
      |--------------------------------------------------
      */
      setMarkedStudentList([
        ...markedStudentList,
        { studentAttendanceId, status },
      ]);
    }

    setShowButton(false);
  }

  function getDayAttendace(item, day, i) {
    // if (!arr) return;
    // const found = arr?.filter((el) => el?.day === day)?.[0];

    /**
    |--------------------------------------------------
    | concatenate day name with date to check if there'school
    | a date entry
    |--------------------------------------------------
    */
    const itemDate = `${day}Date`;

    /**
    |--------------------------------------------------
    | If no date entry is found
    |--------------------------------------------------
    */
    if (!item[itemDate]) {
      /**
      |--------------------------------------------------
      | Check if student is already marked
      |--------------------------------------------------
      */
      const [exists] = markedStudentList?.filter(
        (el) => el?.studentAttendanceId === item?.studentAttendanceId
      );

      return (
        <div className="relative w-full">
          {/**
          |--------------------------------------------------
          | Show student status if it is already marked
          |--------------------------------------------------
          */}
          <div
            className={`rounded-2xl w-full bg-anti_flash_white flex items-center justify-center py-1 cursor-pointer h-7 ${
              exists?.studentAttendanceId === item?.studentAttendanceId &&
              day === dayName
                ? "border-black"
                : "border-grey"
            }`}
            onClick={() => handleCellClick(day, i)}
          >
            {exists === undefined ? (
              <small className="text-yankee_blue">unmarked</small>
            ) : exists?.status && day === dayName ? (
              <small className="text-primary">present</small>
            ) : !exists?.status && day === dayName ? (
              <small className="text-coral_red">absent</small>
            ) : (
              <small className="text-yankee_blue">unmarked</small>
            )}
          </div>

          {/**
          |--------------------------------------------------
          | Buttons to mark present or absent
          |--------------------------------------------------
          */}
          {showButton && index === i && day === dayName ? (
            <div className="absolute top-9 -right-5 -left-5 flex flex-col bg-anti_flash_white mx-1 z-20 shadow-lg rounded-lg border">
              <button
                className="border border-primary m-2 p-1 flex items-center justify-center rounded-2xl"
                onClick={() => handleMark(item?.studentAttendanceId, true)}
              >
                <small className="text-primary text-xs">present</small>
              </button>

              <button
                className="border border-coral_red m-2 p-1 flex items-center justify-center rounded-2xl"
                onClick={() => handleMark(item?.studentAttendanceId, false)}
              >
                <small className="text-coral_red text-xs">absent</small>
              </button>
            </div>
          ) : null}
        </div>
      );
    }

    /**
    |--------------------------------------------------
    | Student is already marked present
    |--------------------------------------------------
    */
    if (item?.[day]) {
      return (
        <div className="rounded-2xl w-full bg-azureish_white flex items-center justify-center py-1 h-7">
          <small className="text-primary text-xs">present</small>
        </div>
      );
    }

    /**
    |--------------------------------------------------
    | Student is already marked absent
    |--------------------------------------------------
    */
    if (!item?.[day]) {
      return (
        <div className="rounded-2xl w-full bg-pale_pink flex items-center justify-center py-1 h-7">
          <small className="text-coral_red text-xs">absent</small>
        </div>
      );
    }
  }

  function getTotalDaysPresent(arr) {
    let count = 0;

    if (arr?.monday) count++;
    if (arr?.tuesday) count++;
    if (arr?.wednesday) count++;
    if (arr?.thursday) count++;
    if (arr?.friday) count++;

    return count;
  }

  return (
    <div className="overflow-auto max-w-[90vw] min-h-[500px]">
      <div className="min-w-[800px]">
        {/* table header */}
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div
            className={`border-r p-3 cursor-pointer ${
              dayName === "monday"
                ? "bg-water text-secondary font-bold"
                : "bg-white"
            }`}
            onClick={() => getDateFromResumptionDate(1, "monday")}
          >
            <h5 className="font-semibold text-sm text-center">Monday</h5>
          </div>

          <div
            className={`border-r p-3 cursor-pointer ${
              dayName === "tuesday"
                ? "bg-water text-secondar font-boldy"
                : "bg-white"
            }`}
            onClick={() => getDateFromResumptionDate(2, "tuesday")}
          >
            <h5 className="font-semibold text-sm text-center">Tuesday</h5>
          </div>

          <div
            className={`border-r p-3 cursor-pointer ${
              dayName === "wednesday"
                ? "bg-water text-second font-boldary"
                : "bg-white"
            }`}
            onClick={() => getDateFromResumptionDate(3, "wednesday")}
          >
            <h5 className="font-semibold text-sm text-center">Wednesday</h5>
          </div>

          <div
            className={`border-r p-3 cursor-pointer ${
              dayName === "thursday"
                ? "bg-water text-seconda font-boldry"
                : "bg-white"
            }`}
            onClick={() => getDateFromResumptionDate(4, "thursday")}
          >
            <h5 className="font-semibold text-sm text-center">Thursday</h5>
          </div>

          <div
            className={`border-r p-3 cursor-pointer ${
              dayName === "friday"
                ? "bg-water text-secondary font-bold"
                : "bg-white"
            }`}
            onClick={() => getDateFromResumptionDate(5, "friday")}
          >
            <h5 className="font-semibold text-sm text-center">Friday</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm text-center">Total</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !attendanceSheet?.length ? (
          <EmptyTable message={"No attendance records found"} />
        ) : null}

        {/* Table body */}

        {attendanceSheet?.map((sheet, i) => (
          <div
            className={`grid grid-cols-8 border-b ${
              studentId === sheet?.studentId ? "active_table_row" : ""
            }`}
            key={i}
          >
            <div
              className="col-span-2 border-r p-3"
              onClick={() => handleRowSelect(sheet?.studentId)}
            >
              <p className="text-independence text-sm capitalize underline cursor-pointer">
                {`${sheet?.lastName || ""} ${sheet?.firstName}`}
              </p>
            </div>

            <div className="border-r p-3">
              <div className="text-independence text-sm capitalize">
                {getDayAttendace(sheet, "monday", i, sheet?.studentId)}
              </div>
            </div>

            <div className="p-3 border-r">
              <div className="text-independence text-sm capitalize">
                {getDayAttendace(sheet, "tuesday", i, sheet?.studentId)}
              </div>
            </div>

            <div className="p-3 border-r">
              <div className="text-independence text-sm capitalize">
                {getDayAttendace(sheet, "wednesday", i, sheet?.studentId)}
              </div>
            </div>

            <div className="p-3 border-r">
              <div className="text-independence text-sm capitalize">
                {getDayAttendace(sheet, "thursday", i, sheet?.studentId)}
              </div>
            </div>

            <div className="p-3 border-r">
              <div className="text-independence text-sm capitalize">
                {getDayAttendace(sheet, "friday", i, sheet?.studentId)}
              </div>
            </div>

            <div className="p-3">
              <p className="text-independence text-sm">
                {getTotalDaysPresent(sheet) || 0} / 5
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttendanceTable;
