import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchReportCardById(reportCardId) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async (id) => {
    setData(null);

    try {
      setLoading(true);

      const res = await client.get(`/result/reportcard/${id}`);

      setData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (reportCardId) request(reportCardId);
  }, [reportCardId, request]);

  return { data, loading };
}
