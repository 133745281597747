import InvitesTable from "../../../shared/components/teachers/InvitesTable";
import useFetchInvitations from "../../../shared/api/invitation/useFetchInvitations";

const PendingInvites = () => {
  const {
    data: invites,
    request: fetchInvites,
    loading: loadingInvites,
  } = useFetchInvitations();

  return (
    <div className="full_page">
      <h1 className="page_title mb-5">Pending Invites</h1>

      <div className="main_content px-5">
        <InvitesTable
          data={invites}
          loading={loadingInvites}
          fetchInvites={fetchInvites}
        />
      </div>
    </div>
  );
};
export default PendingInvites;
