import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import FormInput from "../../form/FormInput";
import SelectInput from "../../form/SelectInput";
import FormButton from "../../form/FormButton";
import useUpdateStudentInfo from "../../../api/classes/students/useUpdateStudentInfo";

const MedicalDetailsForm = ({ details, closeSideModal }) => {
  const { studentId } = useParams();

  const [student, setStudent] = useState({
    weight: details?.weight || "",
    height: details?.height || "",
    genoType: details?.genoType || "",
    bloodType: details?.bloodType || "",
    others: details?.others || "",
  });

  useEffect(() => {
    setStudent({
      weight: details?.weight || "",
      height: details?.height || "",
      genoType: details?.genoType || "",
      bloodType: details?.bloodType || "",
      others: details?.others || "",
    });
  }, [details]);

  const { request: updateMedicalInfoReq, loading: updating } =
    useUpdateStudentInfo();

  async function updateMedicalInfo() {
    await updateMedicalInfoReq(studentId, "medical", student);
    closeSideModal({ refresh: true, type: "basic" });
  }

  return (
    <div className="">
      <h3 className="text-black-text font-semibold mb-5">
        Medical information
      </h3>

      <div className="">
        <FormInput
          label={"Weight"}
          value={student?.weight || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              weight: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Height"}
          value={student?.height || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              height: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <SelectInput
          label={"Genotype"}
          placeholder={student?.genoType || ""}
          required
          handleSelect={(e) =>
            setStudent({ ...student, genoType: e.currentTarget.value })
          }
          options={[
            { name: "AA", value: "AA" },
            { name: "AS", value: "AS" },
            { name: "SS", value: "SS" },
            { name: "AC", value: "AC" },
            { name: "SC", value: "SC" },
          ]}
          className="mb-2"
        />

        <SelectInput
          label={"Blood group"}
          placeholder={student?.bloodType || ""}
          required
          handleSelect={(e) =>
            setStudent({ ...student, bloodType: e.currentTarget.value })
          }
          options={[
            { name: "A+", value: "A+" },
            { name: "A-", value: "A-" },
            { name: "B+", value: "B+" },
            { name: "B-", value: "B-" },
            { name: "AB+", value: "AB+" },
            { name: "AB-", value: "AB-" },
            { name: "O+", value: "O+" },
            { name: "O-", value: "O-" },
          ]}
          className="mb-2"
        />

        <FormInput
          label={"Others"}
          value={student?.others || ""}
          maxLength={11}
          onChange={(e) =>
            setStudent({
              ...student,
              others: e.currentTarget.value,
            })
          }
          className="mb-2"
        />
      </div>

      <div className="border max-w-xs mx-auto my-10">
        <FormButton
          title={"Update"}
          onClick={updateMedicalInfo}
          loading={updating}
        />

        {/* <div style={{ minWidth: "100px" }} /> */}
      </div>
    </div>
  );
};
export default MedicalDetailsForm;
