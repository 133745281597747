import FormButton from "../../../../shared/components/form/FormButton";
import FormInput from "../../../../shared/components/form/FormInput";
import Loader from "../../Loader";

export default function UpdateStudentClass({
  markStudentAs,
  handleCancel,
  handleUpdate,
  updating,
  studentName,
  currentCalendar,
  studentDetailsLoading,
}) {
  function getText() {
    if (markStudentAs === "left") {
      return (
        <>
          <p className="text-sm text-independence mb-2">
            Are you sure you want to mark student as left
          </p>
          <p className="text-sm text-independence mb-1">
            - Existing report card will be archived for{" "}
            {currentCalendar?.session} session
          </p>
          <p className="text-sm text-independence mb-1">
            - Student will be removed from current class
          </p>
          <p className="text-sm text-independence mb-1">
            - Student will be removed from school
          </p>
          <p className="text-sm text-independence">
            - Student records will only be accessed from archives
          </p>
        </>
      );
    }

    if (markStudentAs === "promoted") {
      return (
        <>
          <p className="text-sm text-independence mb-2">
            Are you sure you want to promote student to the next class
          </p>
          <p className="text-sm text-independence mb-1">
            - Student will be promoted to next class
          </p>
          <p className="text-sm text-independence mb-1">
            - Existing report card will be archived for{" "}
            {currentCalendar?.session} session
          </p>
          <p className="text-sm text-independence">
            - Admin will be required to create student report card for next
            session
          </p>
        </>
      );
    }

    if (markStudentAs === "retained") {
      return (
        <>
          <p className="text-sm text-independence mb-2">
            Are you sure you want to retain student in current class.
          </p>
          <p className="text-sm text-independence mb-1">
            - Student will not be promoted to next class
          </p>
          <p className="text-sm text-independence mb-1">
            - Existing report card will be archived for{" "}
            {currentCalendar?.session} session
          </p>
          <p className="text-sm text-independence">
            - Admin will be required to create student report card for next
            session
          </p>
        </>
      );
    }

    if (markStudentAs === "graduated") {
      return (
        <>
          <p className="text-sm text-independence mb-2">
            Are you sure you want to mark student as graduated.
          </p>
          <p className="text-sm text-independence mb-1">
            - Existing report card will be archived for{" "}
            {currentCalendar?.session} session
          </p>
          <p className="text-sm text-independence">
            - Student records will only be accessed from archives
          </p>
        </>
      );
    }
  }

  if (!markStudentAs) return null;

  if (studentDetailsLoading) return <Loader loading={studentDetailsLoading} />;

  return (
    <div>
      <div>
        <h2 className="side_page_title">Update Student Class</h2>
        {getText()}
      </div>

      <div className="mt-8">
        <FormInput value={studentName || ""} className="mb-2" disabled />

        <p className="text-sm text-independence font-bold mt-5">
          This action cannot be undone
        </p>

        <FormButton
          title={`Mark as ${markStudentAs}`}
          className={"mt-2"}
          loading={updating}
          onClick={handleUpdate}
        />

        <FormButton
          title={"Cancel"}
          className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
}
