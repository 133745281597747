import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Pagination from "../../shared/components/Pagination";
import SideModal from "../../shared/components/general/SideModal";
import NewTeacher from "../../shared/components/teachers/NewTeacher";
import useFetchTeachers from "../../shared/api/teachers/useFetchTeachers";
import TableOverview from "../../shared/components/teachers/TableOverview";
import TeachersTable from "../../shared/components/teachers/TeachersTable";
import RemoveTeacher from "../../shared/components/teachers/RemoveTeacher";
import TeacherDetails from "../../shared/components/teachers/TeacherDetails";
import useFetchTeacherBasicInfo from "../../shared/api/teachers/useFetchTeacherBasicInfo";

const Teachers = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userId = query.get("selected");
  const action = query.get("action");

  const { schoolId } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);

  const { data: details, loading: loadingTeacher } =
    useFetchTeacherBasicInfo(userId);
  const {
    loading,
    data: teachers,
    request: getTeachers,
  } = useFetchTeachers(schoolId, page, setPagination);

  async function closeSideModal(params) {
    navigate(`/${schoolId}/teachers`);

    if (params?.refresh) await getTeachers();
  }

  function handleRemoveTeacher() {
    navigate(`/${schoolId}/teachers?selected=${userId}&action=remove-teacher`);
  }

  return (
    <div className={userId || action ? "page" : "full_page"}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">Teachers</h1>
        </div>

        <button
          className="flex items-center justify-center gap-1 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
          onClick={() => navigate(`/${schoolId}/teachers?action=new-teacher`)}
        >
          <FaPlus className="text-sm" />
          <small>Add Teacher</small>
        </button>
      </div>

      <div className="main_content">
        <TableOverview />

        <TeachersTable data={teachers} loading={loading} userId={userId} />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>

      <SideModal isVisible={userId || action} closeSideModal={closeSideModal}>
        {userId && !action ? (
          <TeacherDetails
            details={details}
            loading={loadingTeacher}
            handleRemoveTeacher={handleRemoveTeacher}
          />
        ) : null}

        {action === "new-teacher" ? (
          <NewTeacher closeSideModal={closeSideModal} />
        ) : null}

        {action === "remove-teacher" ? (
          <RemoveTeacher
            closeSideModal={closeSideModal}
            details={details}
            loading={loadingTeacher}
          />
        ) : null}
      </SideModal>
    </div>
  );
};
export default Teachers;
