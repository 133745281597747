import { useState } from "react";

import Loader from "../../shared/components/Loader";
import Pagination from "../../shared/components/Pagination";
import AnnouncementRow from "../../shared/components/announcements/AnnouncementRow";
import useFetchSchoolAnnouncement from "../../shared/api/announcement/useFetchSchoolAnnouncement";

const Announcements = () => {
  const [page, setPage] = useState(1);
  const {
    data: announcements,
    pagination,
    loading,
  } = useFetchSchoolAnnouncement(page);

  return (
    <div>
      <div className="page">
        <div className="flex items-center mb-10">
          <h1 className="page_title flex-1">Announcements</h1>
        </div>

        <div className="main_content">
          <div className="">
            {loading ? <Loader loading={loading} /> : null}

            {!loading &&
              announcements?.map((announcement, i) => (
                <AnnouncementRow key={i} announcement={announcement} />
              ))}

            <Pagination setPage={setPage} pagination={pagination} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Announcements;
