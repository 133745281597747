import { useCallback, useState } from "react";

import client from "../client";
import { handleToast } from "../../utils/functions";
import handleApiError from "../../hooks/handleApiError";

export default function useCancelInvite() {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (inviteId) => {
    try {
      setLoading(true);
      const { data } = await client.delete(`/auth/invite/${inviteId}`);

      setLoading(false);
      handleToast(data?.message);

      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
}
