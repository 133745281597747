import { useState } from "react";

import FormInput from "../../../../shared/components/form/FormInput";
import FormButton from "../../../../shared/components/form/FormButton";
import useAddPrincipalRemark from "../../../../shared/api/cards/useAddPrincipalRemark";
import { handleToast } from "../../../utils/functions";

const PrincipalRemark = ({
  reportCard,
  fetchReportCard,
  closeSideModal,
  action,
}) => {
  const [principalRemark, setPrincipalRemark] = useState(
    reportCard?.principalRemark
  );
  const { request: submitRequest, loading: submitting } =
    useAddPrincipalRemark();

  async function submitRemark() {
    if (!principalRemark) return handleToast("Please add remark", "warning");

    const isSuccess = await submitRequest(reportCard?.reportCardId, {
      principalRemark,
    });

    if (isSuccess) {
      fetchReportCard();
      closeSideModal();
    }
  }

  if (action !== "principal-remark") return null;

  return (
    <div>
      <div className="mb-3">
        <h2 className="side_page_title !mb-2 capitalize">Principal Remark</h2>
        <p className="text-xs text-independence">
          Add principal remark to report card
        </p>
      </div>

      <div className="mt-10">
        <FormInput
          label={"Principal Remark"}
          value={principalRemark || ""}
          onChange={(e) => setPrincipalRemark(e.currentTarget.value)}
        />

        <FormButton
          onClick={submitRemark}
          loading={submitting}
          title="Update Remark"
        />

        <FormButton
          title={"Cancel"}
          className={"!bg-transparent !text-coral_red mt-3 !border-coral_red"}
          onClick={closeSideModal}
        />
      </div>
    </div>
  );
};

export default PrincipalRemark;
