import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";

import { getAverage } from "../../../shared/utils/functions";

import IMAGES from "../../../shared/constant/images";
import Loader from "../../../shared/components/Loader";
import EmptyTable from "../../../shared/components/EmptyTable";
import useFetchReportCardById from "../../../shared/api/cards/useFetchReportCardById";
import ArchivedScorecardsTableBody from "../../../shared/components/archives/reportCards/ArchivedScorecardsTableBody";
import ArchivedScorecardsTableHeader from "../../../shared/components/archives/reportCards/ArchivedScorecardsTableHeader";
import ArchivedScorecardsTableOverview from "../../../shared/components/archives/reportCards/ArchivedScorecardsTableOverview";

export default function ArchivedReportcardDetails() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");

  const { classId, schoolId, reportCardId } = useParams();

  const { data: reportcard, loading: loadingReportCard } =
    useFetchReportCardById(reportCardId);

  if (loadingReportCard) {
    return <Loader loading={loadingReportCard} />;
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5 capitalize">
          {`${reportcard?.student?.lastName || ""} ${
            reportcard?.student?.firstName || ""
          }'s`}{" "}
          Report Card
        </h1>

        <div className="table_wrapper">
          <div className="main_content_header">
            <NavLink
              to={`/${schoolId}/archives/classes/${classId}/reportcard?session=${session}`}
            >
              <button className="main_content_header_btn">Back to cards</button>
            </NavLink>
          </div>
        </div>

        {!loadingReportCard && reportcard ? (
          <div className="main_content mb-10">
            <ArchivedScorecardsTableOverview
              obtainableScore={reportcard?.obtainableScore}
              obtainedScore={reportcard?.obtainedScore}
              average={`${getAverage(
                reportcard?.obtainedScore,
                reportcard?.obtainableScore
              )}%`}
              reportCard={reportcard}
            />

            <div className="table_wrapper">
              <div className="min-w-[800px]">
                <ArchivedScorecardsTableHeader />

                {loadingReportCard ? (
                  <Loader loading={loadingReportCard} />
                ) : null}

                {!loadingReportCard && !reportcard?.scorecards?.length ? (
                  <EmptyTable
                    image={IMAGES.bookImage}
                    message={"No scorecard records found"}
                  />
                ) : null}

                <ArchivedScorecardsTableBody data={reportcard?.scorecards} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
