import { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Pagination from "../../../shared/components/Pagination";
import SideModal from "../../../shared/components/general/SideModal";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import useFetchClassStudents from "../../../shared/api/classes/students/useFetchClassStudents";
import ModifyClassTeacher from "../../../shared/components/classes/classDetails/ModifyClassTeacher";
import ClassStudentsTable from "../../../shared/components/classes/classDetails/ClassStudentsTable";
import ClassDetailsOverview from "../../../shared/components/classes/classDetails/ClassDetailsOverview";

const ClassDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const action = query.get("action");

  const { classId, schoolId } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);

  const {
    loading: classLoading,
    data: classDetails,
    request: fetchClassDetails,
  } = useFetchClassDetails(classId);
  const { loading: loadingStudents, data: students } = useFetchClassStudents(
    classId,
    page,
    setPagination
  );

  function closeSideModal() {
    navigate(`/${schoolId}/classes/${classId}`);
  }

  return (
    <div className={action ? "page" : "full_page"}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">Students</h1>

          <p className="page_subtitle">
            {classDetails
              ? `(${classDetails?.category} ${classDetails?.suffix})`
              : null}
          </p>
        </div>

        <button
          className="flex items-center justify-center gap-1 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
          onClick={() =>
            navigate(`/${schoolId}/classes/${classId}/add-student`)
          }
        >
          <FaPlus className="text-sm" />
          <small>Add Student</small>
        </button>
      </div>

      {/* <ClassNavigationBar activeIndex={0} /> */}

      <div className="main_content">
        <ClassDetailsOverview
          classDetails={classDetails}
          loading={classLoading}
        />

        <ClassStudentsTable
          data={students}
          loading={loadingStudents}
          // studentId={studentId}
        />

        <Pagination setPage={setPage} pagination={pagination} />
      </div>

      <SideModal isVisible={action} closeSideModal={closeSideModal}>
        <ModifyClassTeacher
          classDetails={classDetails}
          action={action}
          refresh={fetchClassDetails}
        />
      </SideModal>
    </div>
  );
};

export default ClassDetails;
