import React from "react";
import { CiEdit } from "react-icons/ci";
import DataPlaceholder from "../../general/DataPlaceholder";

export default function MedicalDetails({ details, onEdit }) {
  // console.log(detail);
  return (
    <div className="bg-white px-3 py-5 rounded-sm">
      <div className="flex items-center justify-between mb-3">
        <h4>Medical Information</h4>

        <button
          className="flex items-center gap-2 text-primary text-sm"
          onClick={() => onEdit("medical")}
        >
          <span>Edit</span>
          <CiEdit />
        </button>
      </div>

      {/**
      |--------------------------------------------------
      | Details
      |--------------------------------------------------
      */}
      <div className="grid grid-cols-3 gap-y-4 gap-x-3">
        <DataPlaceholder label={"Weight"} value={details?.weight || "N/A"} />
        <DataPlaceholder label={"Height"} value={details?.height || "N/A"} />

        <DataPlaceholder
          label={"Blood group"}
          value={details?.bloodType || "N/A"}
        />
        <DataPlaceholder
          label={"Genotype"}
          value={details?.genoType || "N/A"}
        />
        <DataPlaceholder label={"Others"} value={details?.others || "N/A"} />
      </div>
    </div>
  );
}
