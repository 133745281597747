import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { handleToast } from "../../../utils/functions";

import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";

export default function useUpdateEvent() {
  const { navigate, schoolId } = useParams();

  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      try {
        setLoading(true);

        const res = await client.patch(
          "/calendar-event/" + payload?.calendarEventId,
          payload
        );

        handleToast(res.data?.message);
        setLoading(false);
        navigate(`/${schoolId}/calendar?date=${payload?.startDate}`);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [navigate, schoolId]
  );

  return { loading, request };
}
