import { useLocation, useNavigate, useParams } from "react-router-dom";

import NewClass from "../../shared/components/classes/NewClass";
import SideModal from "../../shared/components/general/SideModal";
import ClassTable from "../../shared/components/classes/ClassTable";
import useCreateSubClass from "../../shared/api/classes/useCreateSubClass";
import useFetchSchoolClasses from "../../shared/api/classes/useFetchSchoolClasses";

const Classes = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selected = query.get("selected");

  const navigate = useNavigate();
  const { schoolId } = useParams();

  const {
    loading,
    juniorClasses,
    seniorClasses,
    request: fetchClasses,
  } = useFetchSchoolClasses();

  const { request: createNewClassRequest, loading: newClassLoading } =
    useCreateSubClass();

  async function createNewClass() {
    await createNewClassRequest({ schoolId, category: selected });
    closeSideModal();
    await fetchClasses();
  }

  function getTotalStudents(item) {
    if (!item) return 0;

    return item?.maleStudents + item?.femaleStudents;
  }

  function getTeacherDetails(item) {
    if (!item?.teacherFirstName && !item?.teacherLastName) return "N/A";

    const teacherName = `${item?.teacherFirstName || ""} ${
      item?.teacherLastName || ""
    }`;

    return teacherName;
  }

  function handleNewClassRequest(params) {
    if (newClassLoading) return;
    navigate(`/${schoolId}/school-classes?selected=${params?.category}`);
  }

  function closeSideModal() {
    if (newClassLoading) return;
    navigate(`/${schoolId}/school-classes`);
  }

  return (
    <div>
      <div className={"page"}>
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Classes</h1>
        </div>

        {/* Junior Calsses */}
        <ClassTable
          loading={loading}
          classes={juniorClasses}
          getTeacherDetails={getTeacherDetails}
          getTotalStudents={getTotalStudents}
          label={"Junior Classes"}
          handleNewClass={handleNewClassRequest}
        />

        {/* Senior Classes */}
        <ClassTable
          loading={loading}
          classes={seniorClasses}
          getTeacherDetails={getTeacherDetails}
          getTotalStudents={getTotalStudents}
          label={"Senior Classes"}
          handleNewClass={handleNewClassRequest}
        />
      </div>

      {/* Sidebar */}
      <SideModal isVisible={selected} closeSideModal={closeSideModal}>
        <NewClass
          category={selected}
          closeSideModal={closeSideModal}
          createNewClass={createNewClass}
          submitting={newClassLoading}
        />
      </SideModal>
    </div>
  );
};
export default Classes;
