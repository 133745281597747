import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import IMAGES from "../../constant/images";
import FormButton from "../form/FormButton";
import CalendarEvent from "./CalendarEvent";
import useFetchCalendarEventsByDate from "../../api/calendar/events/useFetchCalendarEventsByDate";

// TODO: Crosscheck that events are general and school events alone
const SchoolEvents = ({ selectedDate }) => {
  const navigate = useNavigate();
  const { schoolId } = useParams();

  const { data: events, loading } = useFetchCalendarEventsByDate(selectedDate);

  function handleNewEvent() {
    navigate(`/${schoolId}/calendar/event/new/${selectedDate}`);
  }

  if (!selectedDate) return null;

  return (
    <div className="py-4 px-3 bg-ghost_white rounded-sm mb-10">
      <h5>School Events</h5>

      <div className="bg-white rounded-sm my-5">
        {!loading && !events?.length ? (
          <div>
            <div className="flex items-center justify-center w-[90%] mx-auto">
              <img
                src={IMAGES.emptyCalendarImage}
                alt=""
                className="w-full h-full object-contain"
              />
            </div>

            <p className="text-independence text-center mt-5">
              No event for selected date
            </p>
          </div>
        ) : null}

        {!loading &&
          events?.map((event, i) => (
            <CalendarEvent
              onClick={() => {
                navigate(
                  `/${schoolId}/calendar/event/edit/${event?.calendarEventId}`
                );
              }}
              key={i}
              date={moment(event?.startDate).format("DD/MM/YY")}
              title={event?.title}
              subtitle={`${event?.startTime} - ${event?.endTime}`}
            />
          ))}
      </div>

      {selectedDate ? (
        <FormButton title={"Create Event"} onClick={handleNewEvent} />
      ) : null}
    </div>
  );
};
export default SchoolEvents;
