import React from "react";
import { useSelector } from "react-redux";

import { selectSchoolDetails } from "../../../redux/slices/school/selectors";
import BillingAddress from "../../../shared/components/settings/billing/BillingAddress";
import BillingOverview from "../../../shared/components/settings/billing/BillingOverview";
import BillingNavigationBar from "../../../shared/components/settings/billing/BillingNavigationBar";
import useFetchSchoolBillingDetails from "../../../shared/api/payment/useFetchSchoolBillingDetails";
import BillingInvoiceSettings from "../../../shared/components/settings/billing/BillingInvoiceSettings";

export default function Billing() {
  const school = useSelector(selectSchoolDetails);
  const { data: billingDetails } = useFetchSchoolBillingDetails(
    school?.schoolId
  );

  /**
  |--------------------------------------------------
  | 
  |--------------------------------------------------
  */
  return (
    <div>
      <div className="page">
        {/**
        |--------------------------------------------------
        | Page title
        |--------------------------------------------------
        */}
        <div className="mb-10 flex items-center justify-between">
          <h1 className="page_title">Billing</h1>
        </div>

        {/**
        |--------------------------------------------------
        | Navigation bar
        |--------------------------------------------------
        */}
        <BillingNavigationBar activeIndex={0} />

        {/**
        |--------------------------------------------------
        | Main content
        |--------------------------------------------------
        */}
        <div className="main_content !pb-0 mb-10">
          {/**
            |--------------------------------------------------
            | Billing Overview
            |--------------------------------------------------
            */}
          <BillingOverview billingDetails={billingDetails} />
        </div>

        <div className="main_content mb-10">
          {/**
            |--------------------------------------------------
            | Billing Invoice settings
            |--------------------------------------------------
            */}
          <BillingInvoiceSettings billingDetails={billingDetails} />
        </div>

        <div className="main_content">
          {/**
            |--------------------------------------------------
            | Billing address
            |--------------------------------------------------
            */}
          <BillingAddress billingDetails={billingDetails} />
        </div>
      </div>
    </div>
  );
}
