import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { PiBooks } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { useEffect, useState } from "react";
import { GrScorecard } from "react-icons/gr";
import { GiBookshelf } from "react-icons/gi";
import { MdOutlineCoPresent } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { NavLink, useLocation, useParams } from "react-router-dom";

import Logo from "./Logo";
import { useSelector } from "react-redux";
import { selectCalendar } from "../../redux/slices/school/selectors";

const ClassSidebar = ({ toggleMenu }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const term = query.get("term");
  const session = query.get("session");
  const { schoolId, classId } = useParams();

  const [activeTab, setActiveTab] = useState("");
  const currentCalendar = useSelector(selectCalendar);

  useEffect(() => {
    setActiveTab(location.pathname?.split("/"));
  }, [location, setActiveTab]);

  // const routes = [
  //   {
  //     activeIndex: 2,
  //     label: "Dashboard",
  //     icon: <RxDashboard />,
  //     activeIndexLabel: "dashboard",
  //     route: `/${schoolId}/dashboard`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Teachers",
  //     icon: <FiUsers />,
  //     activeIndexLabel: "teachers",
  //     route: `/${schoolId}/teachers`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Classes",
  //     icon: <GoDeviceDesktop />,
  //     activeIndexLabel: "classes",
  //     route: `/${schoolId}/classes`,
  //   },
  //   {
  //     activeIndex: 2,
  //     label: "Archives",
  //     icon: <BiCabinet />,
  //     activeIndexLabel: "archives",
  //     submenus: [
  //       {
  //         activeIndex: 3,
  //         label: " Report Cards",
  //         activeIndexLabel: "reportcards",
  //         route: `/${schoolId}/archives/classes`,
  //       },
  //     ],
  //   },
  // ];

  return (
    <ProSidebar
      className="h-full bg-white border-none"
      backgroundColor="white"
      width="230px"
    >
      <div className="px-8 py-5 mb-5 border-b ">
        <Logo />
      </div>

      <Menu
        menuItemStyles={{
          button: {
            [`&`]: {
              height: "40px",
            },
          },
        }}
        stye
      >
        <MenuItem
          icon={<FiUsers />}
          component={<NavLink to={`/${schoolId}/classes/${classId}`} />}
          className={`${
            activeTab?.length === 4 ||
            ["add-student", "student"]?.includes(activeTab?.[4])
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Students
        </MenuItem>

        <MenuItem
          icon={<PiBooks />}
          component={
            <NavLink to={`/${schoolId}/classes/${classId}/subjects`} />
          }
          className={`${
            activeTab?.[4] === "subjects"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Subjects
        </MenuItem>

        <MenuItem
          icon={<MdOutlineCoPresent />}
          component={
            <NavLink to={`/${schoolId}/classes/${classId}/attendance`} />
          }
          className={`${
            activeTab?.[4] === "attendance"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Attendance
        </MenuItem>

        <MenuItem
          icon={<GrScorecard />}
          component={
            <NavLink to={`/${schoolId}/classes/${classId}/scorecards`} />
          }
          className={`${
            activeTab?.[4] === "scorecards"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Scorecards
        </MenuItem>

        <MenuItem
          icon={<GiBookshelf />}
          component={
            <NavLink
              to={`/${schoolId}/classes/${classId}/reportcards?session=${
                session || currentCalendar?.session
              }&term=${term || currentCalendar?.term}`}
            />
          }
          className={`${
            ["reportcards", "students"].includes(activeTab?.[4])
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Report Cards
        </MenuItem>

        <MenuItem
          component={<NavLink to={`/${schoolId}/school-classes`} />}
          icon={<BiLogOut size={16} />}
          className="sidebar_inactive !text-coral_red"
        >
          Exit Class
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default ClassSidebar;
