import { useNavigate, useParams } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";

import EmptyTable from "../../../../shared/components/EmptyTable";
import Loader from "../../../../shared/components/Loader";

const ClassStudentsTable = ({ loading, data, studentId }) => {
  const { classId, schoolId } = useParams();
  const navigate = useNavigate();

  function handleNavigateToDetails(params) {
    navigate(`/${schoolId}/classes/${classId}/student/${params?.studentId}`);
  }

  function handleNavigateToReportcard(params) {
    navigate(
      `/${schoolId}/classes/${classId}/students/${params?.studentId}/reportcard`
    );
  }

  return (
    <div className="overflow-auto max-w-[90vw] min-h-[500px]">
      <div className="min-w-[1000px]">
        {/* table header */}
        <div className="grid grid-cols-7 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">D.O.B</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Parent Number</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Gender</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Date Joined</h5>
          </div>

          <div className="p-3"></div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            //   image={subjectBookImage}
            message={"No student record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((student, i) => (
          <div
            className={`grid grid-cols-7 border-b ${
              studentId === student?.studentId ? "active_table_row" : ""
            }`}
            key={i}
          >
            <div
              className="col-span-2 border-r p-3"
              onClick={() => handleNavigateToDetails(student)}
            >
              <p className="text-independence text-sm capitalize underline hover:text-primary cursor-pointer">
                {student?.lastName} {student?.firstName}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm">{student?.dob}</p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {student?.parentContact}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {student?.gender?.[0]}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">
                {new Date(student?.createdAt)?.toLocaleDateString()}
              </p>
            </div>

            {/* <div
              className="p-3 border-r"
              onClick={() => handleNavigateToDetails(student)}
            >
              <button className="flex items-center gap-3">
                <small>Details</small>
                <FaArrowRightLong className="text-sm" />
              </button>
            </div> */}

            <div
              className="p-3"
              onClick={() => handleNavigateToReportcard(student)}
            >
              <button className="flex items-center gap-3">
                <small>Report Card</small>
                <FaArrowRightLong className="text-sm" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClassStudentsTable;
