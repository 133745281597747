import React from "react";
import useFetchAcademicSessions from "../../api/archives/useFetchAcademicSessions";

export default function AcademicSessions({ handleSelect, session }) {
  const { data: academicSessions } = useFetchAcademicSessions();

  return (
    <div className="mb-5 flex items-center gap-3">
      {academicSessions?.map((item, i) => (
        <div
          className={`w-fit px-4 py-2 rounded-lg cursor-pointer ${
            item?.session === session ? "bg-primary" : "bg-white"
          }`}
          key={i}
          onClick={() => handleSelect(item)}
        >
          <p
            className={`text-xs mb-1 ${
              item?.session === session ? "text-white" : "text-yankee_blue"
            }`}
          >
            Session
          </p>
          <p
            className={`font-semibold text-sm ${
              item?.session === session ? "text-white" : "text-yankee_blue"
            }`}
          >
            {item?.session}
          </p>
        </div>
      ))}
    </div>
  );
}
