import { useCallback, useState } from "react";
import client from "../../client";
import handleApiError from "../../../hooks/handleApiError";
import { handleToast } from "../../../utils/functions";

const useUpdateStudentInfo = () => {
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (id, _type, payload) => {
    setLoading(true);

    try {
      const { data } = await client.patch(`/student/${id}/${_type}`, payload);
      handleToast(data?.message);

      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { request, loading };
};

export default useUpdateStudentInfo;
