import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

const useFetchTeacherBasicInfo = (teacherId) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = useCallback(async (id) => {
    setLoading(true);

    try {
      const res = await client.get(`/user/${id}`);

      setData(res.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (teacherId) request(teacherId);
  }, [request, teacherId]);

  return { data, loading };
};

export default useFetchTeacherBasicInfo;
