import { useCallback, useEffect, useState } from "react";
import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";

export default function useFetchClassHistoryById(id) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async (_id) => {
    try {
      setLoading(true);

      const res = await client.get("/archives/class-history/" + _id);

      setData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    if (id) request(id);
  }, [id, request]);

  return { data, loading, request };
}
