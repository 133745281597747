import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { selectSchoolDetails } from "../../../../redux/slices/school/selectors";

import Loader from "../../Loader";
import FormButton from "../../form/FormButton";
import useFetchSchool from "../../../api/school/useFetchSchool";
import useFetchGeneralCurriculum from "../../../api/school/useFetchGeneralCurriculum";
import useSaveToSchoolCurriculum from "../../../api/school/useSaveToSchoolCurriculum";
import { handleToast } from "../../../utils/functions";

const SortSubjects = ({ category, action, refresh, closeSideModal }) => {
  const navigate = useNavigate();
  const { schoolId } = useParams();

  const school = useSelector(selectSchoolDetails);
  const { loading: loadingCurriculum, data: curriculums } =
    useFetchGeneralCurriculum(category);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const { request: saveCurriculumRequest, loading: savingCurriculum } =
    useSaveToSchoolCurriculum();
  const { request: fetchSchool } = useFetchSchool();

  async function saveCurriculum() {
    if (!category) return;

    if (category === "senior" && school?.hasSortSeniorCurriculum) {
      handleToast("Subjects have been sorted");
      return navigate(`/${schoolId}/settings/curriculum/senior`);
    }

    if (category === "junior" && school?.hasSortJuniorCurriculum) {
      handleToast("Subjects have been sorted");
      return navigate(`/${schoolId}/settings/curriculum/junior`);
    }

    if (selectedSubjects?.length <= 0)
      handleToast("Please select at least 10 subjects");

    await saveCurriculumRequest(category, { subjects: selectedSubjects });

    if (refresh) {
      await fetchSchool(schoolId);
      refresh(category);
      closeSideModal();
    }
  }

  function handleSelect(params) {
    if (savingCurriculum) return;

    const index = selectedSubjects?.findIndex(
      (sub) => sub?.curriculumId === params?.curriculumId
    );

    if (index >= 0) {
      setSelectedSubjects(
        selectedSubjects?.filter(
          (el) => el?.curriculumId !== params?.curriculumId
        )
      );
    } else {
      setSelectedSubjects([...selectedSubjects, params]);
    }
  }

  function handleCancel() {
    setSelectedSubjects([]);
    navigate(`/${schoolId}/settings/curriculum/${category}`);
  }

  if (loadingCurriculum) {
    return <Loader loading={loadingCurriculum} />;
  }

  if (action !== "sort") return null;

  return (
    <div>
      <div>
        <h2 className="side_page_title !mb-2 capitalize">
          Sort {category} Subjects
        </h2>

        <p className="text-sm text-independence mb-1">
          Choose subjects that will be taught accross your {category} school.
        </p>
        <p className="text-sm text-independence">
          Selected subjects will be added to all {category} classes
        </p>
      </div>

      <div className="mt-6">
        <p className="text-sm text-independence mb-1">
          Total state curriculums:{" "}
          <span className="font-bold">{curriculums?.length}</span>
        </p>
        <p className="text-sm text-independence">
          Your Total Selections:{" "}
          <span className="font-bold">{selectedSubjects?.length}</span>
        </p>
      </div>

      <div className="mt-3 mb-10">
        {!loadingCurriculum &&
          curriculums?.map((subject, i) => {
            const index = selectedSubjects?.findIndex(
              (sub) => sub?.curriculumId === subject?.curriculumId
            );

            return (
              <div
                key={i}
                className={`flex items-center px-3 mb-3 h-12 rounded-sm cursor-pointer ${
                  index >= 0 ? "bg-primary" : "bg-cultured "
                }`}
                onClick={() => handleSelect(subject)}
              >
                <p
                  className={`text-sm font-semibold capitalize ${
                    index >= 0 ? "text-white" : "text-yankee_blue"
                  }`}
                >
                  {subject?.name}
                </p>
              </div>
            );
          })}
      </div>

      <FormButton
        title={"Add Subject"}
        onClick={saveCurriculum}
        loading={savingCurriculum}
      />

      <p
        className="text-center text-coral_red mt-1 py-3 cursor-pointer"
        onClick={handleCancel}
      >
        Cancel
      </p>
    </div>
  );
};

export default SortSubjects;
