import { useState } from "react";

import Loader from "../../Loader";
import EmptyTable from "../../EmptyTable";
import { getAverage, getGrade, getGradeColor } from "../../../utils/functions";

const ReportCardTable = ({ loading, data, handleSelect, handleMark }) => {
  const [showButton, setShowButton] = useState(false);
  const [index, setIndex] = useState(null);

  function getReportCardActions(card, i) {
    if (card?.studentClassHistory?.endedSessionAs) {
      return (
        <p className="text-independence text-sm capitalize">
          {card?.studentClassHistory?.endedSessionAs}
        </p>
      );
    }

    if (!card?.studentClassHistory?.endedSessionAs) {
      return (
        <div
          className="relative w-full"
          onClick={() => {
            setShowButton(!showButton);
            setIndex(i);
          }}
        >
          <small className="text-yankee_blue">N/A</small>

          {showButton && index === i ? (
            <div className="absolute top-9 -right-5 -left-5 flex flex-col bg-white mx-1 z-20 shadow-lg rounded-lg border">
              {card?.category === "JSS3" || card?.category === "SSS3" ? (
                <>
                  <button
                    className="border border-primary m-2 p-1 flex items-center justify-center rounded-sm"
                    onClick={() => handleMark("graduated", card?.studentId)}
                  >
                    <small className="text-primary text-xs">
                      Graduate Student
                    </small>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="border border-primary m-2 p-1 flex items-center justify-center rounded-sm"
                    onClick={() => handleMark("promoted", card?.studentId)}
                  >
                    <small className="text-primary text-xs">
                      Promote Student
                    </small>
                  </button>

                  <button
                    className="border border-saffron m-2 p-1 flex items-center justify-center rounded-sm"
                    onClick={() => handleMark("retained", card?.studentId)}
                  >
                    <small className="text-saffron text-xs">
                      Retain Student
                    </small>
                  </button>
                </>
              )}
              <button
                className="border border-coral_red m-2 p-1 flex items-center justify-center rounded-sm"
                onClick={() => handleMark("left", card?.studentId)}
              >
                <small className="text-coral_red text-xs">Mark as left</small>
              </button>
            </div>
          ) : null}
        </div>
      );
    }
  }

  return (
    <div className="overflow-auto max-w-[90vw] min-h-[500px]">
      <div className="min-w-[700px]">
        {/* table header */}
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Obtainable Score</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Obtained Score</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Average</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Grade</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Started session as</h5>
          </div>

          <div className="p-3">
            <h5 className="font-semibold text-sm">Status</h5>
          </div>
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            // image={subjectBookImage}
            message={"No report card record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((card, i) => {
          const avg = getAverage(card?.obtainedScore, card?.obtainableScore);
          const grade = getGrade(
            (card?.obtainedScore / card?.obtainableScore) * 100
          );

          return (
            <div
              className="grid grid-cols-8 border-b cursor-pointer hover:bg-primary_light_20"
              key={i}
            >
              <div
                className="col-span-2 border-r p-3 underline cursor-pointer"
                onClick={() => handleSelect(card)}
              >
                <p className="text-independence text-sm capitalize">
                  {card?.lastName} {card?.firstName}
                </p>
              </div>

              <div className="border-r p-3">
                <p className="text-independence text-sm">
                  {card?.obtainableScore}
                </p>
              </div>

              <div className="p-3 border-r">
                <p className={`text-independence text-sm`}>
                  {card?.obtainedScore}
                </p>
              </div>

              <div className="p-3 border-r">
                <p className={`text-independence text-sm capitalize`}>
                  {`${avg}%`}
                </p>
              </div>

              <div className="p-3 border-r">
                <p
                  className={`text-dark_electric_blue text-sm ${getGradeColor(
                    (card?.obtainedScore / card?.obtainableScore) * 100
                  )}`}
                >
                  {grade}
                </p>
              </div>

              <div className="p-3 border-r ">
                <p className="text-independence text-sm capitalize">
                  {card?.studentClassHistory?.startedSessionAs === "entry"
                    ? "New Entry"
                    : card?.studentClassHistory?.startedSessionAs}
                </p>
              </div>

              <div className="p-3">{getReportCardActions(card, i)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportCardTable;
