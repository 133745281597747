import { useCallback, useEffect, useState } from "react";
import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchScorecardsByClassSubjectId(
  classSubjectId,
  calendar
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(
    async (_classSubjectId, _calendar) => {
      setLoading(true);
      setData([]);

      try {
        const res = await client.get(
          `/result/${_classSubjectId || classSubjectId}/scorecard?session=${
            _calendar?.session || calendar?.session
          }&term=${_calendar?.term || calendar?.term}`
        );

        setData(res.data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [calendar, classSubjectId]
  );

  useEffect(() => {
    if (classSubjectId && calendar) {
      request(classSubjectId, calendar);
    }
  }, [classSubjectId, request, calendar]);

  return { data, loading, request };
}
