import { useCallback, useState } from "react";
import handleApiError from "../../../hooks/handleApiError";
import client from "../../client";
import { handleToast } from "../../../utils/functions";

export default function usePromotStudentToSeniorClass() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(async (payload) => {
    try {
      setLoading(true);

      const res = await client.post(
        "/archives/archived-students/promote",
        payload
      );

      setLoading(false);
      setData(res.data?.data);
      handleToast(res.data?.message);
      return true;
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  }, []);

  return { data, loading, request };
}
