import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { handleToast } from "../../utils/functions";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useCompleteResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        setLoading(true);

        const { data } = await client.post(
          `/auth/password/reset/complete`,
          payload
        );

        setLoading(false);
        handleToast(data?.message);

        if (data?.success) {
          navigate("/login");
        }
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [navigate]
  );

  return { request, loading };
}
