import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import AcademicSessions from "../../../shared/components/archives/AcademicSessions";
import useFetchClassReportCards from "../../../shared/api/archives/reportCards/useFetchClassReportCards";
import ArchivedReportCardOverview from "../../../shared/components/archives/reportCards/ArchivedReportCardOverview";
import ArchivedReportCardTable from "../../../shared/components/archives/reportCards/ArchivedReportCardTable";

export default function ArchivedReportcards() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");

  const navigate = useNavigate();
  const { classId, schoolId } = useParams();
  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const {
    data: reportCards,
    loading: loadingCards,
    request: getReportCards,
  } = useFetchClassReportCards(classId);

  const [selectedTerm, setSelectedTerm] = useState("first");

  function handleSelect(card) {
    navigate(
      `/${schoolId}/archives/classes/${classId}/reportcard/${card?.reportCardId}?session=${session}`
    );
  }

  function handleSelectSession(params) {
    navigate(
      `/${schoolId}/archives/classes/${classId}/reportcard?session=${params?.session}`
    );
  }

  useEffect(() => {
    if (session) {
      getReportCards(session, selectedTerm);
    }
  }, [getReportCards, session, selectedTerm]);

  return (
    <div>
      <div className="full_page">
        <h1 className="page_title mb-5">
          Reportcards{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <AcademicSessions
          handleSelect={handleSelectSession}
          session={session}
        />

        <div className="main_content">
          <ArchivedReportCardOverview
            classDetails={classDetails}
            classLoading={classLoading}
            teacher={`${reportCards?.[0]?.teacher?.lastName || ""} ${
              reportCards?.[0]?.teacher?.firstName || ""
            }`}
            reportCardsLength={reportCards?.length}
            handleSelectCalendar={setSelectedTerm}
            selectedTerm={selectedTerm}
            session={session}
          />

          <ArchivedReportCardTable
            data={reportCards}
            loading={loadingCards}
            handleSelect={handleSelect}
          />

          {/* <Pagination setPage={setPage} pagination={pagination} /> */}
        </div>
      </div>
    </div>
  );
}
