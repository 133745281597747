import React from "react";
import { CiEdit } from "react-icons/ci";
import DataPlaceholder from "../../general/DataPlaceholder";

export default function ClassHistories({ classHistories, onEditClassHistory }) {
  // console.log(detail);
  return (
    <div className="bg-white px-3 py-5 rounded-sm">
      <div className="flex items-center justify-between mb-3">
        <h4>Class Histories</h4>
      </div>

      {/**
      |--------------------------------------------------
      | histories
      |--------------------------------------------------
      */}
      {classHistories?.map((history, index) => (
        <div className="border-t border-cultured py-4" key={index}>
          <div className="flex items-start justify-between gap-5">
            <h4 className="text-yankee_blue font-bold text-sm capitalize">
              {history?.school?.name} ({history?.category})
            </h4>

            <button
              className="flex items-center gap-2 text-primary text-sm pb-3 pl-5"
              onClick={() => onEditClassHistory(history)}
            >
              <span>Edit</span>
              <CiEdit />
            </button>
          </div>

          <div className="grid grid-cols-3 gap-y-6 gap-x-3">
            <DataPlaceholder
              label={"Session"}
              value={history?.session || "N/A"}
            />
            <DataPlaceholder
              label={"Started As"}
              value={history?.startedSessionAs || "N/A"}
            />

            <DataPlaceholder
              label={"Ended As"}
              value={history?.endedSessionAs || "N/A"}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
