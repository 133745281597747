import { useState } from "react";

import Loader from "../../../../shared/components/Loader";
import { handleToast } from "../../../../shared/utils/functions";
import FormInput from "../../../../shared/components/form/FormInput";
import FormButton from "../../../../shared/components/form/FormButton";
import useFetchScorecardById from "../../../../shared/api/cards/useFetchScorecardById";
import useDeleteScorecardById from "../../../../shared/api/cards/useDeleteScorecardById";

const DeleteScorecard = ({
  action,
  scorecardId,
  handleCancelDeleteScorecard,
  fetchReportCard,
}) => {
  const [card, setCard] = useState("");
  const { request: deleteScorecard, loading: deleting } =
    useDeleteScorecardById();
  const { data: scorecard, loading: loadingScorecard } =
    useFetchScorecardById(scorecardId);

  async function handleDeleteScorecard() {
    if (card !== scorecard?.name)
      return handleToast("Enter correct scorecard name", "warning");

    const isSuccessful = await deleteScorecard(scorecardId);

    if (isSuccessful) {
      handleToast("Scorecard have been deleted");
      await fetchReportCard();
      setCard(null);
      handleCancelDeleteScorecard();
    }
  }

  if (!scorecardId && action !== "delete") return null;

  if (loadingScorecard) return <Loader loading={loadingScorecard} />;

  return (
    <div>
      <div>
        <h2 className="side_page_title">Delete Scorecard</h2>
        <p className="text-sm text-independence">
          Are you sure you want to delete scorecard? <br /> This action cannot
          be undone
        </p>
      </div>

      <div className="mt-8">
        <FormInput
          placeholder={scorecard?.name}
          value={card}
          onChange={(e) => setCard(e.currentTarget.value)}
          className="mb-2"
        />

        <p className="text-sm text-independence">
          Enter "<span className="text-black font-bold">{scorecard?.name}</span>
          " to permanently delete scorecard
        </p>

        <FormButton
          title={"Delete Scorecard"}
          className={"!bg-coral_red mt-5"}
          loading={deleting}
          onClick={handleDeleteScorecard}
        />

        <FormButton
          title={"Cancel"}
          className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
          onClick={handleCancelDeleteScorecard}
        />
      </div>
    </div>
  );
};

export default DeleteScorecard;
