import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchStudentReportCard(session, term) {
  const { studentId } = useParams();

  const [loading, setLoading] = useState(false);
  const [reportCard, setReportCard] = useState(null);
  const [scorecards, setScorecards] = useState(null);
  const [firstTermScorecards, setFirstTermScorecards] = useState(null);
  const [secondTermScorecards, setSecondTermScorecards] = useState(null);

  // console.log(firstTermScorecards, "firstTermScorecards");
  // console.log(secondTermScorecards, "secondTermScorecards");

  const request = useCallback(
    async (_session, _term) => {
      setReportCard(null);
      setScorecards([]);
      setLoading(true);

      try {
        const res = await client.get(
          `/student/${studentId}/reportcard?session=${_session}&term=${_term}`
        );

        if (res.data?.success) {
          setReportCard(res.data?.data);

          setScorecards(res.data?.data?.scorecards);
          setFirstTermScorecards(res.data?.data?.firstTermScorecards);
          setSecondTermScorecards(res.data?.data?.secondTermScorecards);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [studentId]
  );

  useEffect(() => {
    if (session && term) request(session, term);
  }, [request, session, term]);

  return {
    reportCard,
    scorecards,
    loading,
    request,
    firstTermScorecards,
    secondTermScorecards,
  };
}
