import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import client from "../client";
import {
  setJuniorCurriculums,
  setSeniorCurriculums,
} from "../../../redux/slices/curriculum/slice";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchGeneralCurriculum(category) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(
    async (_category) => {
      try {
        setLoading(true);
        const res = await client.get(`/curriculum/${_category || category}`);

        setData(res.data?.data);
        if (_category === "junior")
          dispatch(setJuniorCurriculums(res.data?.data));

        if (_category === "senior")
          dispatch(setSeniorCurriculums(res.data?.data));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch, category]
  );

  useEffect(() => {
    if (category) request(category);
  }, [request, category]);

  return { loading, data, request };
}
