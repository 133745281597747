import React, { useState } from "react";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import SelectInput from "../../form/SelectInput";
import { handleToast } from "../../../utils/functions";
import useFetchSchoolClasses from "../../../api/classes/useFetchSchoolClasses";
import useFetchClassHistoryById from "../../../api/archives/students/useFetchClassHistoryById";
import usePromotStudentToSeniorClass from "../../../api/archives/students/usePromotStudentToSeniorClass";

export default function PromoteStudentToSeniorClass({
  selected,
  closeSideModal,
}) {
  const { data, loading } = useFetchClassHistoryById(selected);
  const { loading: promoting, request: promoteStudent } =
    usePromotStudentToSeniorClass();

  const { loading: loadingClasses, seniorClasses } = useFetchSchoolClasses();

  const [payload, setPayload] = useState({});

  async function promoteStudentRequest() {
    if (!payload?.department)
      return handleToast("Select student department", "warning");

    payload.studentId = data?.studentId;
    payload.schoolId = data?.schoolId;

    console.log(payload, "payload");
    const isSuccess = await promoteStudent(payload);

    if (isSuccess) {
      closeSideModal();
    }
  }

  if (loading || loadingClasses) {
    return <Loader loading={loading || loadingClasses} />;
  }

  return (
    <div>
      <h3 className="side_page_title">Promote Student</h3>

      <p className="text-sm text-independence mb-2">
        Are you sure you want to promote student to senior class?
      </p>

      <div className="mt-5">
        <FormInput
          disabled
          className="mb-2"
          label={"Student Name"}
          placeholder={`${data?.student?.lastName} ${data?.student?.firstName}`}
        />

        <SelectInput
          label={"Department"}
          placeholder="Department"
          required
          handleSelect={(e) =>
            setPayload({ ...payload, department: e.currentTarget.value })
          }
          options={[
            { name: "Science Department", value: "science" },
            { name: "Art Department", value: "art" },
            { name: "Commercial Department", value: "commercial" },
          ]}
          className="mb-2"
        />

        <SelectInput
          label={"Class"}
          placeholder="Select class"
          required
          handleSelect={(e) =>
            setPayload({ ...payload, classId: e.currentTarget.value })
          }
          options={seniorClasses}
          optionKeyLabel="category"
          optionValueLabel="classId"
          className="mb-2"
        />

        <p className="text-sm text-independence font-bold mt-5">
          This action cannot be undone
        </p>

        <FormButton
          className={"mt-2"}
          loading={promoting}
          title={"Promote student"}
          onClick={promoteStudentRequest}
        />

        <FormButton
          title={"Cancel"}
          className={"!bg-transparent !text-coral_red !border-coral_red mt-3"}
          onClick={closeSideModal}
        />
      </div>
    </div>
  );
}
