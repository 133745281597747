import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { handleToast } from "../../../utils/functions";
import { selectSchoolDetails } from "../../../../redux/slices/school/selectors";

import Loader from "../../Loader";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import useFetchSubjectBySchoolSubjectId from "../../../api/school/useFetchSubjectBySchoolSubjectId";
import useRemoveSubjectFromSchoolCurriculum from "../../../api/school/useRemoveSubjectFromSchoolCurriculum";

const DeleteSubject = ({
  schoolSubjectId,
  closeSideModal,
  refresh,
  category,
}) => {
  const navigate = useNavigate();
  const { schoolId } = useParams();
  const school = useSelector(selectSchoolDetails);

  const { loading: removingSubject, request: removeSubjectRequest } =
    useRemoveSubjectFromSchoolCurriculum(false);
  const { data: subject, loading: loadingSubject } =
    useFetchSubjectBySchoolSubjectId(schoolSubjectId);

  const [text, setText] = useState("");

  async function removeSubject() {
    if (text !== subject?.name)
      return handleToast("Enter correct subject name");

    // if subject have not bee sorted, navigate to sort subject page
    if (category === "junior" && !school?.hasSortJuniorCurriculum) {
      handleToast("Please select subjects for junior classes");

      return navigate(`/${schoolId}/settings/curriculum/junior?action=sort`);
    }

    if (category === "senior" && !school?.hasSortSeniorCurriculum) {
      handleToast("Please select subjects for senior classes");

      return navigate(`/${schoolId}/settings/curriculum/senior?action=sort`);
    }

    const isSuccess = await removeSubjectRequest(schoolId, { schoolSubjectId });

    if (isSuccess) {
      setText("");
      await refresh();
      closeSideModal();
    }
  }

  if (loadingSubject) return <Loader loading={loadingSubject} />;

  if (!schoolSubjectId) return null;

  return (
    <div>
      <div>
        <h2 className="side_page_title !mb-2 capitalize">Delete Subject</h2>

        <p className="text-sm text-independence">
          Are you sure you want to delete this subject? <br /> This action
          cannot be undone
        </p>
      </div>

      <div className="mt-8">
        <FormInput
          placeholder={subject?.name}
          value={text}
          onChange={(e) => setText(e.currentTarget.value)}
          className="mb-2"
        />

        <p className="text-sm text-independence">
          Enter "<span className="text-black font-bold">{subject?.name}</span>"
          to permanently delete subject
        </p>

        <FormButton
          title={"Delete Subject"}
          className={"!bg-coral_red mt-5"}
          loading={removingSubject}
          onClick={() => removeSubject(schoolSubjectId)}
        />

        <FormButton
          title={"Cancel"}
          className={
            "!bg-transparent !text-coral_red border !border-coral_red mt-3"
          }
          onClick={() => {
            setText("");
            closeSideModal();
          }}
        />
      </div>
    </div>
  );
};

export default DeleteSubject;
