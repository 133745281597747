import React, { useState } from "react";
import { useParams } from "react-router-dom";

import SideModal from "../../../shared/components/general/SideModal";
import useFetchClassDetails from "../../../shared/api/classes/useFetchClassDetails";
import SubjectTable from "../../../shared/components/classes/classSubjects/SubjectTable";
import useFetchClassSubjects from "../../../shared/api/classes/subjects/useFetchClassSubjects";
import SubjectOverview from "../../../shared/components/classes/classSubjects/SubjectOverview";
import ModifyClassSubject from "../../../shared/components/classes/classSubjects/ModifyClassSubject";

const ClassSubjects = () => {
  const { classId } = useParams();

  const { loading: classLoading, data: classDetails } =
    useFetchClassDetails(classId);
  const {
    data: subjects,
    request: fetchSubjects,
    loading: subjectLoading,
  } = useFetchClassSubjects(classId);

  const [selectedSubject, setSelectedSubject] = useState(null);

  function closeSideModal() {
    setSelectedSubject(null);
  }

  return (
    <div className={selectedSubject ? "page" : "full_page"}>
      <div className="mb-5 flex items-center justify-between">
        <div className="page_title_container">
          <h1 className="page_title">Subjects</h1>

          <p className="page_subtitle">
            {classDetails
              ? `(${classDetails?.category} ${classDetails?.suffix})`
              : null}
          </p>
        </div>
      </div>

      {/* <ClassNavigationBar activeIndex={2} /> */}

      <div className="main_content">
        <SubjectOverview
          classDetails={classDetails}
          classLoading={classLoading}
        />

        <SubjectTable
          loading={subjectLoading}
          data={subjects}
          handleSelectSubject={setSelectedSubject}
          selectedSubject={selectedSubject}
        />
      </div>

      <SideModal isVisible={selectedSubject} closeSideModal={closeSideModal}>
        <ModifyClassSubject
          selectedSubject={selectedSubject}
          setSelectedSubject={setSelectedSubject}
          fetchSubjects={fetchSubjects}
        />
      </SideModal>
    </div>
  );
};

export default ClassSubjects;
