import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";
import { setTotalUnreadNotifications } from "../../../redux/slices/notification/slice";

export default function useFetchNotificationsCount(recipientId) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (_recipientId) => {
      try {
        setLoading(true);

        const res = await client.get(`/notification?recipient=${_recipientId}`);

        const unreadNotifications = res.data?.data?.filter(
          (el) => !el?.isRead
        )?.length;
        dispatch(setTotalUnreadNotifications(unreadNotifications));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (recipientId) request(recipientId);
  }, [request, recipientId]);

  return { loading };
}
